import AwardsHeader from "./AwardsHeader";

interface AwardsCardProps{
    header: string;
    bgColor : string;
    content : React.ReactNode;
    CompanyRights?: boolean;
}

function AwardsCard ({header , content , bgColor , CompanyRights} : AwardsCardProps) {
    return(
        <div className={`rtl py-[1.875rem] px-[3.875rem] gap-4 flex flex-col ${bgColor}`}>
            <AwardsHeader title={header} CompanyRights={CompanyRights} />
            {content}
        </div>
    )
}

export default AwardsCard;