import CementAcrylic from './CementAcrylic';
import { useTranslation } from 'react-i18next';

interface DifferenceProbs {
  comparison: Array<any>;
}

function Difference({ comparison }: DifferenceProbs) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center w-full gap-7 custom-height-mq:gap-11">
      <div className="text-xs custom-height-mq:text-2xl font-bold text-center text-[#1C4585] w-[80%] custom-height-mq:w-[38.25rem]">
        {`${t('The difference between')} ${comparison[0]['name']} ${t('and')} ${
          comparison[1]['name']
        }`}
      </div>
      <div className="flex flex-col gap-3 custom-height-mq:flex-row w-full justify-between items-center">
        <CementAcrylic
          image={comparison[0]['image']}
          header={comparison[0]['name']}
          content={comparison[0]['description']}
        />
        <CementAcrylic
          image={comparison[1]['image']}
          header={comparison[1]['name']}
          content={comparison[1]['description']}
        />
      </div>
    </div>
  );
}

export default Difference;
