import PointsIcon from '../../assets/PointsArrowIcon.svg';


interface ProductCardPointProps{
    Points : string;
}


function ProductCardPoint ({Points} : ProductCardPointProps) {
    return(
        <div className="flex flex-row gap-2 justify-center items-center w-[7.25rem] h-[1.8rem   ] custom-height-mq:w-[13rem] custom-height-mq:h-[3rem] border-2 border-[#FBAD4A] rtl">
            <img src={PointsIcon} alt="Points" className="w-[0.9rem] h-[0.9rem] custom-height-mq:w-[1.5rem] custom-height-mq:h-[1.5rem]" />
            <div className='font-normal text-xs custom-height-mq:text-xl text-[#5A5A5A] arabicLanguage mb-[0.3rem] custom-height-mq:mb-[0.5rem]'>{Points}</div>
        </div>
    )
}

export default ProductCardPoint;