interface MixingCardProps {
    icon:string;
    title: string;
    weight : string;
}

function MixingCard({title , weight , icon} : MixingCardProps) {
    return(
        <div className="flex flex-col justify-between items-center h-[11.639375rem]">
            <img src={icon} alt={title} className="w-[8.5625rem] h-[7.375rem]" />
            <div className="text-sm font-normal text-[#5A5A5A]">{title}</div>
            <div className="text-xs font-normal flex items-center justify-center border-2 border-solid border-[#FBAD4A] w-[6.25rem] h-[1.9375rem] text-[#1C4585]">{weight}</div>
        </div>
    )
}

export default MixingCard;