import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

interface MessageContentProbs {
    items : Array <any>;
}

function MessageContent ({items}: MessageContentProbs){
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    const images = items[0].images; 
    return (
        <div className={`flex flex-col gap-5 custom-height-mq:mt-[1rem] mb-[1.75rem]  ${currentLanguage === "ar" ? "rtl" : "ltr"}`}>
            <div className="text-justify font-normal text-[0.75rem] leading-[1.2rem] custom-height-mq:text-base custom-height-mq:leading-[1.6rem] text-[#1C4585] capitalize">{items[0]['title']}</div>
            <div className='flex flex-col gap-2 custom-height-mq:flex-row'>
            {images.map((image : any , index : any) => (
                    <img key={index} src={image.image} alt={`Message ${index + 1}`} className={`w-full ${images.length <= 3 ? `md:w-${Math.floor(12 / images.length)}/12` : 'md:w-4/12'}`} />
                ))}
            </div>
        </div>
    )
}

export default MessageContent;