import { Modal } from 'antd';

interface VideoModalProps {
  visible: boolean;
  onCancel: () => void;
  video?: string;
}

function VideoModal({ visible, onCancel , video }: VideoModalProps) {
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={false}
      centered={true}
      className="!w-[unset]"
      styles={{ mask: { backgroundColor: "rgba(28, 69, 133, 0.8)" } , content: { borderRadius : "0px" , maxWidth: "52.75rem" , maxHeight : "90vh" , overflow: "auto" } }}
    >
      <div className="flex items-center justify-center mt-[1.2rem] p-[0.5rem] custom-height-mq:mt-2 custom-height-mq:p-[2rem] pb-[0.5rem] w-full h-[20rem] custom-height-mq:h-[26rem] custom-height-mq:w-[46rem]">
      <iframe
        width="100%"
        height="100%"
        src={video}
        title="About Chema Foam"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    />
      </div>
    
    </Modal>
  );
}

export default VideoModal;
