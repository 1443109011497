import { configureStore } from '@reduxjs/toolkit';
import languageReducer from '../i18next/langSlice';
import authReducer from './authSlice';
import userReducer from './userSlice';
import productsReducer from './productsSlice';
import systemsReducer from './systemsSlice';
import applicationsReducer from './applicationsSlice';
import apiService from './apiService';

const store : any = configureStore({
  reducer: {
    language: languageReducer,
    auth: authReducer,
    user: userReducer,
    products: productsReducer, 
    systems: systemsReducer, 
    applications: applicationsReducer, 
    [apiService.reducerPath]: apiService.reducer, 
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiService.middleware), 
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
