import { Link } from "react-router-dom";

interface LeftSideNavLinkProbs {
    icon : string;
    title: string;
    LinkTo: string;
    onClick:  () => void;
    awards?: boolean;
}


function LeftSideNavLink ({icon , title , LinkTo , onClick , awards} : LeftSideNavLinkProbs)
{
    return(
        <Link to={LinkTo} onClick={onClick} className="flex flex-row justify-start items-center gap-2">
            <img src={icon} alt={title} className="w-[1.5rem] h-[1.5rem]" />
            <div className={`text-white font-normal text-sm ${awards ? "!arabicLanguage mb-[0.3rem]" : ""} `}>{title}</div>
        </Link>
    )
} 

export default LeftSideNavLink;