import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/store';
import {
  useGetPageDataQuery,
  useGetProductsDataQuery,
} from '../../store/apiService';
import ProductDocumentsCard from '../Downloads/components/ProductDocumentsCard';
import ContactUsButton from '../Main components/ContactUsButton';
import Filter from '../Main components/Filter/Filter';
import Header from '../Main components/Header/Header';
import MainFooter from '../Main components/MainFooter/MainFooter';
import MainHeader from '../Main components/MainHeader/MainHeader';
import NavigateTopButton from '../Main components/NavigateTopButton';
import ProductLayerImage from '../Products/assets/ProductLayersIamge.svg';
import VideoIcon from './assets/VideoIcon.svg';
import CalcIcon from './assets/CalcIcon.svg';
import DownloadIcon from './assets/DownloadGreyIcon.svg';
import ProductsButton from './components/ProductsButton';
import InstallMethodIcon from './assets/InstalMethod.svg';
import DescIcon from './assets/Products.svg';
import TechCharIcon from './assets/TechChar.svg';
import AppPlacesIcon from './assets/AppPlaces.svg';
import MixingIcon from './assets/Mixing.svg';
import Accordion from '../Main components/Accordion';
import ProductsCard from '../Home/components/ProductsCard/ProductsCard';
import InquiriesButton from './components/InquiriesButton';
import DescContent from './components/DescContent';
import TechCharContent from './components/TechCharContent';
import ApplicationPlacesContent from './components/ApplicationPlacesModal/ApplicationPlacesModal';
import MixingContent from './components/MixingContent/MixingContent';
import InstallationMethodContent from './components/InstallationMethodContent/InstallationMethodContent';
import MobileBottomNavBar from '../Main components/MobileBottomNavBar/MobileBottomNavBar';
import HouseIcon from '../Main components/MobileBottomNavBar/assets/HomeIcon.svg';
import AppIcon from '../Main components/MobileBottomNavBar/assets/AppsIcon.svg';
import ProductIcon from '../Main components/MobileBottomNavBar/assets/ProductIcon.svg';
import ContactIcon from '../Main components/MobileBottomNavBar/assets/ContactIcon.svg';
import MoreIcon from '../Main components/MobileBottomNavBar/assets/MoreIcon.svg';

function Products() {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const selectedProductId = useSelector(
    (state: RootState) => state.products.selectedProduct
  );
  const { t } = useTranslation();
  const [selectedProductIdState, setSelectedProductIdState] =
    useState(selectedProductId);
  const { data, error, isLoading, refetch } = useGetPageDataQuery('Products', {
    skip: false,
  });
  const { data: productData, refetch: refetchProductData } =
    useGetProductsDataQuery(selectedProductIdState, {
      skip: !selectedProductIdState,
    });

  useEffect(() => {
    refetch();
    refetchProductData();
  }, [currentLanguage, refetch, error]);

  useEffect(() => {
    {
      data && setSelectedProductIdState(data['product'].id);
    }
  }, [data]);

  useEffect(() => {
    if (selectedProductIdState) {
      refetchProductData();
    }
  }, [selectedProductIdState, refetchProductData]);

  useEffect(() => {
    if (selectedProductId) {
      setSelectedProductIdState(selectedProductId);
    }
  }, [selectedProductId]);

  const BottomNavBarIcons = [
    HouseIcon,
    AppIcon,
    ProductIcon,
    ContactIcon,
    MoreIcon,
  ];
  const BottomNavBarTitles = [
    t('Home'),
    t('Applications'),
    t('Products'),
    t('Contact Us'),
    t('More'),
  ];

  const ProductsFilter = useMemo(() => {
    if (!data) return { sections: [] };

    const sections = data.sidebar.map(
      (category: { title: any | string | string[]; products: any[] }) => ({
        name: t(category.title),
        subItems: category.products.map(
          (app: { id: number; title: any | string | string[] }) => ({
            id: app.id.toString(),
            title: t(app.title),
          })
        ),
      })
    );

    return { sections };
  }, [data, t]);

  return (
    data &&
    productData && (
      <div className="flex flex-col justify-center items-center">
        <Header Active="Products" />
        <MobileBottomNavBar
          icons={BottomNavBarIcons}
          titles={BottomNavBarTitles}
          active={t('Products')}
        />
        <NavigateTopButton />
        <ContactUsButton />
        <MainHeader title={t('Products')} page={t('Products')} />
        <div
          className={`w-[20.964375rem] custom-height-mq:w-[78rem] flex flex-col custom-height-mq:flex-row items-center justify-center custom-height-mq:items-start custom-height-mq:justify-between py-[1rem] custom-height-mq:py-[4rem] ${
            currentLanguage === 'ar' ? 'rtl' : 'ltr'
          }`}
        >
          <Filter
            title={t('Products')}
            items={ProductsFilter}
            selectedProductId={selectedProductIdState}
            onFilterChange={setSelectedProductIdState}
          />
          <div className="w-full custom-height-mq:w-[55.6875rem] pt-5 custom-height-mq:pt-0 flex flex-col items-start gap-11">
            <div className="flex flex-col gap-5 custom-height-mq:gap-0 custom-height-mq:flex-row w-full items-center justify-between">
              <ProductDocumentsCard
                title={productData['product']['title']}
                image={productData['product']['card_image'].image}
                weight={productData['product']['weight']}
                unitOfMeasure={productData['product']['unit_of_measure']}
                Product={true}
              />
              <div className="relative flex justify-center items-center w-[17.0625rem] h-[12rem] custom-height-mq:w-[36.375rem] custom-height-mq:h-[23.1875rem]">
                <img
                  src={productData['product']['header_image'].image}
                  alt="Layers"
                  className="w-full h-full object-cover"
                />
                <div className="absolute w-[11.0625rem] h-[6rem] custom-height-mq:w-[272px] custom-height-mq:h-[160px] bg-[rgba(31,53,102,0.65)] flex flex-col gap-2 items-center justify-center">
                  <div className="font-bold text-3xl custom-height-mq:text-6xl text-white">
                    {productData['product']['coverage_rate']}
                    <span className="font-[400] text-xs custom-height-mq:sm">
                      {productData['product']['coverage_rate_unite']}
                    </span>
                  </div>
                  <div className="text-sm custom-height-mq:text-lg font-bold text-[#FBAD4A]">
                    {productData['product']['coverage_rate_note']}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 custom-height-mq:gap-0 custom-height-mq:flex-row w-full items-center justify-between">
              <ProductsButton
                title={t('Watching Video')}
                video={productData['product']['videos'][0]['video_url']}
                icon={VideoIcon}
              />
              <ProductsButton
                title={t('Quantity Calculation')}
                icon={CalcIcon}
                cardImage={productData['product']['card_image'].image}
                productTitle={productData['product']['title']}
                subtitle={productData['product']['subtitle']}
                coverageRate={productData['product']['coverage_rate']}
                coverageRateUnit={productData['product']['coverage_rate_unite']}
                weight={productData['product']['weight']}
                unitOfMeasure={productData['product']['unit_of_measure']}
              />
              <ProductsButton
                title={t('Download Files')}
                icon={DownloadIcon}
                cardImage={productData['product']['card_image'].image}
                productTitle={productData['product']['title']}
                subtitle={productData['product']['subtitle']}
                weight={productData['product']['weight']}
                unitOfMeasure={productData['product']['unit_of_measure']}
                pdf={productData['product']['pdf']}
              />
            </div>
            <div className="flex flex-col gap-5 w-full">
              <Accordion
                title={t('The Description')}
                Icon={DescIcon}
                Content={
                  <DescContent
                    description={productData['product']['description']}
                    descriptionAdvantage={
                      productData['product']['description_advantages']
                    }
                    image={productData['product']['desreption_image'].image}
                  />
                }
                Product={true}
              />
              <Accordion
                title={t('Technical Characteristics')}
                Icon={TechCharIcon}
                Content={
                  <TechCharContent
                    image={
                      productData['product'][
                        'technical__characteristic_image'
                      ] &&
                      productData['product']['technical__characteristic_image']
                        .image
                    }
                    technicalCharacteristic={
                      productData['product']['technical__characteristic']
                    }
                  />
                }
                Product={true}
              />
              <Accordion
                title={t('Application Places')}
                Icon={AppPlacesIcon}
                Content={
                  <ApplicationPlacesContent
                    places={productData['product']['places']}
                  />
                }
                Product={true}
              />
              {productData['product']['mixing'].length > 0 && (
                <Accordion
                  title={t('Mixing proportions and preparation for use')}
                  Icon={MixingIcon}
                  Content={
                    <MixingContent mixing={productData['product']['mixing']} />
                  }
                  Product={true}
                />
              )}
              {productData['product']['installtion'].length > 0 &&
                productData['product']['install_notes'].length > 0 && (
                  <Accordion
                    title={t('Installation Method')}
                    Icon={InstallMethodIcon}
                    Content={
                      <InstallationMethodContent
                        installation={productData['product']['installtion']}
                        installationNotes={
                          productData['product']['install_notes']
                        }
                      />
                    }
                    Product={true}
                  />
                )}
            </div>
            <div className="flex flex-col gap-8 w-full">
              <div className="font-normal self-center custom-height-mq:self-start text-base custom-height-mq:text-2xl text-[#1EBAE5]">
                {t('Other Related Products')}
              </div>
              <div className="flex flex-col gap-4 custom-height-mq:flex-row justify-start items-center w-full">
                {productData['related_product']
                  .slice(0, 3)
                  .map((product: any) => (
                    <ProductsCard
                      key={product.id}
                      id={product.id}
                      title={product.title}
                      subtitle={product.subtitle}
                      description={product.description}
                      weight={product.weight}
                      unitOfMeasure={product.unit_of_measure}
                      coverageRate={product.coverage_rate}
                      coverageRateUnit={product.coverage_rate_unite}
                      howToApply={product.how_to_apply}
                      cardImage={product.card_image.image}
                      descriptionAdvantages={product.description_advantages}
                      pdf={product.pdf}
                      places={product.places}
                      video={product['videos'][0]['video_url']}
                    />
                  ))}
              </div>
            </div>
            <InquiriesButton />
          </div>
        </div>
        <MainFooter />
      </div>
    )
  );
}

export default Products;
