import { useSelector } from 'react-redux';
import InstallationMethodCard from './InstallationMethodCard';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store/store';

interface InstallationMethodContentProbs {
  installation: Array<any>;
  installationNotes: Array<any>;
}

function InstallationMethodContent({
  installation,
  installationNotes,
}: InstallationMethodContentProbs) {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const { t } = useTranslation();

  return (
    <div className="w-[18.4375rem] custom-height-mq:w-[50rem] gap-5 flex flex-col items-start justify-center">
      {installation.length > 0 && (
        <div className="flex flex-col items-center custom-height-mq:flex-row justify-between w-full">
          {installation[0] && (
            <InstallationMethodCard
              number="01"
              image={installation[0]['image']}
              title={installation[0]['title']}
              paragraph={installation[0]['descreption']}
            />
          )}
          {installation[1] && (
            <InstallationMethodCard
              number="02"
              image={installation[1]['image']}
              title={installation[1]['title']}
              paragraph={installation[1]['descreption']}
            />
          )}
          {installation[2] && (
            <InstallationMethodCard
              number="03"
              image={installation[2]['image']}
              title={installation[2]['title']}
              paragraph={installation[2]['descreption']}
            />
          )}
          {installation[3] && (
            <InstallationMethodCard
              number="04"
              image={installation[3]['image']}
              title={installation[3]['title']}
              paragraph={installation[3]['descreption']}
            />
          )}
        </div>
      )}
      <div className="text-sm custom-height-mq:text-base font-bold text-[#1C4585]">
        {currentLanguage === 'ar' ? 'ملاحظات هامة:' : 'Notes:'}
      </div>
      {installationNotes.length > 0 && (
        <ul
          className={`list-disc text-[#5A5A5A] capitalize font-normal ${
            currentLanguage === 'ar' ? 'mr-[2rem]' : 'ml-[2rem]'
          } text-xs custom-height-mq:text-sm text-justify`}
        >
          {installationNotes.map((item) => (
            <li key={item.id}>{item.content}</li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default InstallationMethodContent;
