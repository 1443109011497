interface ModalIconProps{
    icon : string;
    title : string;
}

function ModalIcon ({icon , title} : ModalIconProps) {
    return(
        <div className="flex flex-col gap-2 custom-height-mq:gap-5 justify-center items-center">
            <img src={icon} alt="Advantage" className="w-[1.855625rem] h-[1.8rem] custom-height-mq:w-[3.710625rem] custom-height-mq:h-[3.6rem]" loading="lazy" /> 
            <div className="text-center text-xs custom-height-mq:text-sm font-normal leading-20 text-[#1C4585] w-[6.3rem] h-[2.5rem]">
  {title}
</div>
        </div>
    )
}


export default ModalIcon;