import SocialMediaIcon from "../../../Main components/MainFooter/SocialMediaIcon";
import FacebookGreyIcon from "../../../Home/assets/FacebookGreyIconVisible.svg";
import LinkedInGreyIcon from "../../../Home/assets/LinkedInGreyIcon.svg";
import YoutubeGreyIcon from "../../../Home/assets/YoutubeGreyIcon.svg";

interface FollowUsContentProbs {
    YTURL : string;
    FBURL : string;
    LIURL : string;
}

function FollowUsContent ({YTURL , FBURL, LIURL} : FollowUsContentProbs) {
    return(
        <div className="flex flex-row gap-8 px-[1.5rem] custom-height-mq:px-[2.5rem]">
                <SocialMediaIcon link={FBURL} icon={FacebookGreyIcon} ContactUs={true} />
                <SocialMediaIcon link={LIURL} icon={LinkedInGreyIcon} ContactUs={true} />
                <SocialMediaIcon link={YTURL} icon={YoutubeGreyIcon} ContactUs={true} />
        </div>
    )
}

export default FollowUsContent;