import { useEffect, useRef, useState } from "react";
import FacebookPostCard from "./FacebookPostCard";
import ExtendCardButton from "./ExtendCardButton";
import { ReactComponent as BlueFacebookIcon } from "../../assets/BlueFacebookIcon.svg";

interface DreamsCardProps {
  index: number;
  Active: boolean;
  icon: string;
  image: string;
  uploadDate: string;
  postHeader: string;
  context: string;
  onClick: () => void;
  updated: boolean;
  post_url : string;
}

function DreamsCard({
  index,
  Active,
  icon,
  image,
  uploadDate,
  postHeader,
  context,
  onClick,
  updated,
  post_url,
}: DreamsCardProps) {
  const cardRef = useRef<HTMLDivElement>(null);
  const [rightTransition, setRightTransition] = useState<string>(
    "transform translate-x-2 custom-height-mq:translate-x-5 translate-y-2 custom-height-mq:translate-y-5"
  );
  const [leftTransition, setLeftTransition] = useState<string>(
    "transform translate-x-5 custom-height-mq:translate-x-9 translate-y-5 custom-height-mq:translate-y-9"
  );
  const [direction, setDirections] = useState<boolean>(true);
  const [rotate, setRotate] = useState<boolean>(false);
  const [clicked, setClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
    setClicked(!clicked);
    setRotate(!rotate);
    if (cardRef.current) {
      const scrollHeight = cardRef.current.scrollHeight;
      const clientHeight = cardRef.current.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;

      if (clicked) {
        cardRef.current.scrollTo({ top: 0, behavior: "smooth" });
      } else if (!clicked) {
        cardRef.current.scrollTo({ top: maxScrollTop, behavior: "smooth" });
      }
    }
  };

  useEffect(() => {
    setDirections(!direction);
  }, []);

  const cardStyles = `bg-white overflow-hidden transition-all duration-300 ease-in-out ${
    updated && !Active ? (direction ? rightTransition : leftTransition) : ""
  } transition-transform duration-1000 
  ${
    Active
      ? "max-w-[10.33125rem] h-[19.26125rem] custom-height-mq:max-w-[23.5rem] custom-height-mq:h-[43.8125rem] z-40 fade-in-active"
      : "cursor-pointer user-select-none fade-in-inActive opacity-70 hover:opacity-100 transition-opacity duration-300 ease-in-out max-w-[6.511875rem] h-[9.1225rem] custom-height-mq:max-w-[15.8125rem] custom-height-mq:h-[20.75rem]"
  }`;

  const iconStyles = `absolute right-2 top-2 custom-height-mq:right-5 custom-height-mq:top-5 z-30 transition-all duration-300 ease-in-out ${
    Active
      ? "w-[1.09875rem] h-[1.09875rem] custom-height-mq:w-[2.5rem] custom-height-mq:h-[2.5rem] hover:cursor-pointer"
      : "w-[0.659375rem] h-[0.659375rem] custom-height-mq:w-[1.5rem] custom-height-mq:h-[1.5rem]"
  }`;

  const iconComponent = (
    <a href={post_url} target="_blank" rel="noopener noreferrer">
      <img src={icon} alt="Social Media" className={iconStyles} loading="lazy" />
    </a>
  );

  return (
    <div className={`relative ${Active ? "z-40" : ""}`}>
      <div ref={cardRef} className={cardStyles} onClick={onClick}>
        <img src={image} alt="Chema Facebook" className="w-full h-auto" />
        <FacebookPostCard
          Active={Active}
          uploadDate={uploadDate}
          postHeader={postHeader}
          context={context}
        />
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {iconComponent}
        </div>
        <div
          className={`absolute bottom-3 custom-height-mq:bottom-8 transition-all duration-[0.5s] ${
            clicked ? (rotate ? "left-[10%]" : "left-[44%]") : "left-[44%]"
          } z-30`}
        >
          {Active && (
            <ExtendCardButton
              clicked={clicked}
              onClick={handleClick}
              rotate={rotate}
            />
          )}
        </div>
        {!rotate && (
          <div
            className={`absolute inset-0 Dreams-card-shadow ${
              Active ? "pointer-events-none" : ""
            } z-20`}
          ></div>
        )}
      </div>
    </div>
  );
}

export default DreamsCard;
