interface ProductCardDataContentProps {
    title: string;
  }
  
  function ProductCardDataContent({ title }: ProductCardDataContentProps) {
    return (
      <div className="text-white text-justify font-normal text-xs overflow-hidden line-clamp-[9]">
        {title}
      </div>
    );
  }
  
  export default ProductCardDataContent;
  