import { ReactComponent as BlueVideoIcon } from "../assets/BlueVideoIcon.svg";
import { ReactComponent as BlueCalcIcon } from "../assets/BlueCalcIcon.svg";
import { ReactComponent as BlueDownloadIcon } from "../assets/BlueDownloadIcon.svg";
import { useState } from "react";
import CalcModal from "../../Main components/Modals/CalcModal";
import DownloadModal from "../../Main components/Modals/DownloadModal";
import VideoModal from "../../Main components/Modals/VideoModal";
import React from "react";
import { useTranslation } from "react-i18next";

interface ProductsButtonProbs {
  icon: string;
  title: string;
  productTitle?: string;
  subtitle?: string;
  weight?: number;
  unitOfMeasure?: string;
  coverageRate ?: number;
  coverageRateUnit ?: string;
  cardImage ?: string;
  pdf?: Array<any>;
  video?: string;
}

function ProductsButton({ icon, title , productTitle , subtitle  , weight , unitOfMeasure , coverageRate , coverageRateUnit  , cardImage  , pdf , video }: ProductsButtonProbs) {
  const [isHovered, setIsHovered] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const { t } = useTranslation();

  const ModalContent = React.memo(() => {
    if (title === t("Quantity Calculation")) {
      return <CalcModal visible={modalVisible} onCancel={handleCloseModal} cardImage={cardImage} title={productTitle} subtitle={subtitle} coverageRate={coverageRate} coverageRateUnit={coverageRateUnit} weight={weight} unitOfMeasure={unitOfMeasure} />;
    } else if (title === t("Download Files")) {
      return <DownloadModal visible={modalVisible} onCancel={handleCloseModal} cardImage={cardImage} title={productTitle} subtitle={subtitle} unitOfMeasure={unitOfMeasure} weight={weight} pdf={pdf} />;
    } else if (title === t("Watching Video")) {
      return <VideoModal visible={modalVisible} onCancel={handleCloseModal} video={video} />;
    } else {
      return null;
    }
  });

  return (
    <>
      <div
        onClick={handleOpenModal}
        className="w-[17.0625rem] h-[3.75rem] group bg-[#D7D7D7] hover:bg-[#FBAD4A] flex flex-row gap-2 justify-center items-center hover:cursor-pointer"
      >
        {isHovered ? (
          title === t("Quantity Calculation") ? (
            <BlueCalcIcon className="w-[1.5rem] h-[1.5rem]" />
          ) : title === t("Watching Video") ? (
            <BlueVideoIcon className="w-[1.5rem] h-[1.5rem]" />
          ) : (
            <BlueDownloadIcon className="w-[1.5rem] h-[1.5rem]" />
          )
        ) : (
          <img src={icon} alt="Modal" className="w-[1.5rem] h-[1.5rem]" />
        )}
        <div className="text-left font-bold text-base custom-height-mq:text-lg tracking-normal text-[#5A5A5A]">
          {title}
        </div>
      </div>
      <ModalContent />
    </>
  );
}

export default ProductsButton;
