import React from "react";

interface SocialMediaIconProps {
  icon: string;
  link?: string;
  ContactUs?: boolean;
}

function SocialMediaIcon({ icon, link, ContactUs }: SocialMediaIconProps) {


  return (
    <a
      href={link || "#"}
      target="_blank"
    >
      <img
        src={icon}
        alt="Social Media"
        className={`w-[2.25rem] h-[2.25rem] transition-transform ${
          ContactUs ? "hover:contactUsSocialMediaIconHover hover:scale-[1.3]" : "hover:socialMediaIconHover"
        } hover:cursor-pointer`}
        loading="lazy"
      />
    </a>
  );
}

export default SocialMediaIcon;
