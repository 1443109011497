import YoutubeIcon from "../../Home/components/YoutubeIcon";
import YoutubeFrame from "../../Home/assets/frame.jpeg";
import { useEffect, useState } from "react";
import AOS from 'aos';
import VideoModal from "../../Main components/Modals/VideoModal";

interface VideosCardProps {
    header : string;
    body : string;
    videos : Array<any>;
}

function VideosCard({header,body , videos} : VideosCardProps) {
    const [modalVisible, setModalVisible] = useState(false);
    const handleOpenModal = () => {
        setModalVisible(true);
      };
    
      const handleCloseModal = () => {
        setModalVisible(false);
      };
      useEffect(() => {
        AOS.init({ 
        once: true, 
        duration: 500});
      }, [])
      
    return(
        <div data-aos="zoom-out" className="w-[18.303125rem] h-[15.375rem] custom-height-mq:h-[19.74rem] custom-height-mq:w-[23.5rem] !transition-all duration-50 group border-2 border-solid border-[#FBAD4A] hover:cursor-pointer hover:border-0 hover:ContextCardShadow">
            <div className="h-[67%]">
            <div 
             onClick={handleOpenModal}
             className="flex items-center transition-all justify-center bg-[#1C4585] bg-opacity-20 group-hover:bg-opacity-60 w-full h-[-webkit-fill-available]" >
            <img
              src={YoutubeFrame}
              alt="frame"
              className="w-full h-full"
            />
            <YoutubeIcon />
          </div>
            </div>
            <div className="h-[33%] bg-white flex flex-col justify-center items-center gap-1 custom-height-mq:gap-2">
                <div className="text-sm custom-height-mq:text-xl font-bold text-[#1C4585] text-center">{header}</div>
                <div className="text-[0.65rem] leading-[1rem] custom-height-mq:text-xs font-normal text-[#868686] w-[16.8125rem] text-center">{body}</div>
            </div>
            <VideoModal visible={modalVisible} onCancel={handleCloseModal} video={videos && videos[0]['video_url']} />
        </div>
    )
}

export default VideosCard;