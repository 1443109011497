import NumberString from "./NumberString";
import ProjectsNumberContent from "./ProjectsNumberContent";

interface ProjectsNumberCardProps {
  main: boolean;
  icon: string;
  number: string;
  title: string;
}

function ProjectsNumberCard({
  main,
  icon,
  number,
  title,
}: ProjectsNumberCardProps) {
  return main ? (
    <div className="flex flex-col justify-center items-center gap-2 custom-height-mq:gap-5">
      <img src={icon} alt="Projects" className="w-[4.02375rem] h-[4.02375rem] custom-height-mq:w-[5.3125rem] custom-height-mq:h-[5.3125rem]" loading="lazy" />
      <NumberString number={number} main={main} />
      <ProjectsNumberContent title={title} main={main} />
    </div>
  ) : (
    <div className="flex flex-col justify-center items-center gap-2 custom-height-mq:gap-5">
      <img src={icon} alt="Projects" className="w-[2.5921875rem] h-[2.16rem] custom-height-mq:w-[3.6rem] custom-height-mq:h-[3.825rem]" loading="lazy" />
      <NumberString number={number} main={main} />
      <ProjectsNumberContent title={title} main={main} />
    </div>
  );
}

export default ProjectsNumberCard;
