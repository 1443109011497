interface ProjectsContentProps {
    title: string;
}

function ProjectsContent({title}: ProjectsContentProps) {
  return (
    <div className="text-[#868686] capitalize text-[0.65rem] leading-5 custom-height-mq:text-base custom-height-mq:leading-8 font-normal text-center">
      {title}
    </div>
  );
}

export default ProjectsContent;
