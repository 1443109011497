import { useState } from 'react';
import DownloadGreyIcon from '../assets/DownloadGreyIcon.svg';
import DownloadModal from '../../Main components/Modals/DownloadModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

interface DownloadFileButtonProbs {
  title : string;
  image : string;
  subtitle?: string;
  weight?: number | undefined;
  unitOfMeasure?: string | undefined;
  pdf?: Array<any>;
}

function DownloadFileButton ({pdf , subtitle , title , image , weight , unitOfMeasure} : DownloadFileButtonProbs) {
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    const { t } = useTranslation();
    const [downModalVisible, setdownModalVisible] = useState(false);
    const handleOpenModal = () => {
        setdownModalVisible(true);
      };
    
      const handleCloseModal = () => {
        setdownModalVisible(false);
      };
    return(
        <>
        <div onClick={handleOpenModal} className="w-[13rem] h-[3rem] flex flex-row justify-center items-center bg-[#D7D7D7] hover:bg-[#FBAD4A] gap-3 hover:cursor-pointer">
            <div className={`text-xl font-normal leading-[2rem] tracking-normal text-center text-[#5A5A5A] ${currentLanguage === "ar" ?  "mb-[0.5rem]" : ""}`}>{t("Download File")}</div>
            <img src={DownloadGreyIcon} alt='Download' className='w-[1.018125rem] h-[1.17875rem] mb-1' />
        </div>
        <DownloadModal visible={downModalVisible} onCancel={handleCloseModal} pdf={pdf} title={title} cardImage={image} weight={weight} unitOfMeasure={unitOfMeasure} subtitle={subtitle} />
        </>
    )
}

export default DownloadFileButton;