import ContactUsButton from "../../Main components/ContactUsButton";
import NavigateTopButton from "../../Main components/NavigateTopButton";
import PagesTitle from "../../Main components/pagesTitle";
import UnderlineHeader from "../../Main components/UnderlineHeader";
import AwardsChemaImage from "../assets/AwardsChemaImage.svg";
import AwardsHeader from "../AwardsHeader";
import SideBar from "../SideBar";
import ChemaGroupBlueIcon from "../assets/ChemaGroupBlueIcon.svg";
import AwardsCard from "../Terms&Conditions/AwardsCard/AwardsCard";
import AwardsContentText from "../Terms&Conditions/AwardsCard/AwardsContentText";
import PrizesProductCard from "./PrizesProductCard.tsx/PrizesProductCard";
import Header from "../../Main components/Header/Header";
import MobileBottomNavBar from "../../Main components/MobileBottomNavBar/MobileBottomNavBar";
import TermsAndConditionsIcon from "../assets/TermsAndConditionsIcon.svg";
import PrizesIcon from "../assets/Prizes.svg";
import RegisterIcon from "../assets/RegisterIcon.svg";
import LoginIcon from "../assets/LoginIcon.svg";
import { useGetAwardsDataQuery } from "../../../store/apiService";


function Prizes() {
  const { data: awardsData, error, isLoading } = useGetAwardsDataQuery('products');
  const BottomNavBarIcons = [
    TermsAndConditionsIcon,
    PrizesIcon,
    RegisterIcon,
    LoginIcon,
  ];
  const BottomNavBarTitles = [
    "الشروط والأحكام",
    "جوائز المسابقة",
    "التسجيل في البرنامج",
    "تسجيل الدخول",
  ];

  return awardsData && (
    <div className="arabicLanguage relative flex flex-col min-h-screen">
      <Header Active="" awards={true} />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={"جوائز المسابقة"}
        awards={true}
      />
      <ContactUsButton Awards={true} />
      <NavigateTopButton Awards={true} />
      <AwardsHeader />
      <div className="flex flex-row h-full overflow-hidden justify-center pt-0 custom-height-mq:pt-[6.25rem] items-start right-0 relative">
        <SideBar Active="جوائز المسابقه" />
        <div className="flex flex-col w-screen custom-height-mq:w-[62.125rem] py-0 custom-height-mq:py-[5rem] mr-[14rem]">
          <img
            src={AwardsChemaImage}
            alt="Awards"
            className="w-full object-cover h-[14rem]"
          />
          <div className="relative self-center top-[-1.5rem] custom-height-mq:top-[-3.5rem] h-full overflow-auto">
            <PagesTitle title="جوائز المسابقة" Awards={true} />
          </div>
          <div className="flex flex-col gap-9">
            <div className="flex flex-col justify-center items-center gap-5 custom-height-mq:gap-11 mt-[1.5rem]">
              <UnderlineHeader
                title="الجوائز المتاحة في المسابقة على حسب مجموع النقاط"
                Awards={true}
              />
              <div className="w-[20.875rem] custom-height-mq:w-[55.6875rem] grid grid-cols-2 custom-height-mq:grid-cols-3 gap-5 custom-height-mq:gap-11">
                {awardsData.award_products.map((product : any) => <PrizesProductCard key={product.id} title={product.title} points={product.points} image={product.image} />)}

              </div>
            </div>
            <AwardsCard
              header={"شروط واحكام عامة:"}
              bgColor="bg-[#FBAD4A] bg-opacity-10"
              content={
                <ol className="list-decimal mr-[1.5rem]">
                  <li>
                    <AwardsContentText text="لا يحق لجميع موظفين الشركة او اقاربهم الاشتراك في المسابقة ." />
                  </li>
                  <li>
                    <AwardsContentText text="يحق للعميل الاستمرار في المسابقة حتى بعد حصوله على الهدية والاستمرار في حساب النقاط طوال فترة المسابقة." />
                  </li>
                  <li>
                    <AwardsContentText text="يحق للشركة استبدال ماركة الهدية المطلوبة حسب التواجد في السوق." />
                  </li>
                  <li>
                    <AwardsContentText text="يتم صرف الهدايا للافراد باسمائهم وبياناتهم الشخصية الموجودة على الموقع الخاص بالشركة والتي تم عمل الحساب بها ولا يتم صرفها من خلال توكيل او اي احد من افراد العائلة." />
                  </li>
                  <li>
                    <AwardsContentText text="تبدأ المسابقة في 15 اغسطس 2020 وتنتهي في 14 اغسطس 2023 ويحق للشركة مد المسابقة او انهائها كما يترائى لها." />
                  </li>
                  <li>
                    <AwardsContentText text="يحق للشركة استبدال عدد النقاط الموجودة في اي منتج او اضافة او حذف منتجات من المسابقة طوال فترة المسابقة." />
                  </li>
                  <li>
                    <AwardsContentText text="للشركة الحق في تصوير الفائزين لاستغلالها في اي مواد دعائية ويعد اشتراك العميل في موقع الشركة موافقة ضمنية منه على ذلك." />
                  </li>
                  <li>
                    <AwardsContentText text="في حالة تلف الكارت يتم التوجه الى اقرب فرع او مركز تلوين وتسليم الكارت ويتم اضافة الكارت في حالة عدم شحنه من قبل على رصيد المشترك." />
                  </li>
                </ol>
              }
            />
            <AwardsCard
              CompanyRights={true}
              header={
                "يحق للشركه تعديل عدد النقاط لكل هديه ونوع الهديه فى أى وقت دون أى إنذار مسبق"
              }
              bgColor="bg-[#1EBAE51A] bg-opacity-10"
              content={
                <div className="font-bold text-sm custom-height-mq:text-2xl arabicLanguage text-justify text-[#1C4585]">
                  كروت النقاط الموجودة في العبوات خاصة بالفني المستخدم للعبوة
                  فقط وغير قابلة للتداول او البيع او الشراء وفي حالة اكتشاف ذلك
                  يتم ايقاف المشترك فورا وللشركة الحق في اتخاذ كافة الاجراءات
                  التي تحفظ حقوقها حال اكتشاف اي تلاعب
                </div>
              }
            />
            <img
              src={ChemaGroupBlueIcon}
              alt="Chema Group"
              className="w-[9.98625rem] h-[3.25rem] self-center my-[2rem] mb-[6rem] custom-height-mq:mb-0 custom-height-mq:mt-[7rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prizes;
