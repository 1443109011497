import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface InputLabelProp {
    label: string;
  }
  
  function InputLabel({ label }: InputLabelProp) {
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    return (
      <div className={`text-[#5A5A5A] text-xs custom-height-mq:text-base font-bold ${currentLanguage === "ar" ? "rtl" : "ltr"}`}>{label}</div>
    );
  }
  
  export default InputLabel;
  