interface NumberStringProps {
    number: string;
    main: boolean;
  }
  
  function NumberString({ number, main }: NumberStringProps) {
    return main ? (
      <div className="text-2xl custom-height-mq:text-4xl text-[#1C4585] font-bold leading-6 tracking-tight text-center">
        {number}
      </div>
    ) : (
      <div className="text-xl custom-height-mq:text-3xl text-[#1C4585] font-bold leading-6 tracking-tight text-center">
        {number}
      </div>
    );
  }
  
  export default NumberString;
  