import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

interface StoryContentProbs {
    items : Array<any>;
}

function StoryContent({items} : StoryContentProbs){
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    const splitTitle = items[0].title.split(' ');
    const firstThreeWords = splitTitle.slice(0, 3).join(' ');
    const restOfTitle = splitTitle.slice(3).join(' ');
    return (
        <div className="flex flex-col items-center justify-center custom-height-mq:flex-row gap-5 custom-height-mq:gap-10 custom-height-mq:mt-[1rem] mb-[1.75rem]">
            <div className={`${currentLanguage === "ar" ? "rtl" : "ltr"} flex flex-col gap-5`}>
            <div className="text-justify font-normal text-[0.76rem] leading-[1.2rem] custom-height-mq:text-base custom-height-mq:leading-[1.6rem] text-[#1C4585] capitalize"><strong>{firstThreeWords}</strong>{` ${restOfTitle}`}</div>
            <ul className={`text-[0.65rem] leading-[1rem] custom-height-mq:text-base font-normal custom-height-mq:leading-[1.625rem] tracking-normal text-justify text-[#5A5A5A] capitalize list-disc ${currentLanguage === "ar" ? "mr-5 custom-height-mq:mr-8" : "ml-5 custom-height-mq:ml-8"} `}>
            {items[0].list.map((item: any) => (
                        <li key={item.id}>{(item.content)}</li>
                    ))}
            </ul>
            </div>
            <img src={items[0]['images'][0].image} alt="Story Image" className="w-fit h-fit sm:w-[26.5rem] sm:h-[23rem]" />
        </div>
    )
}

export default StoryContent;