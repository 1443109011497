import { useTranslation } from "react-i18next";
import PhoneCallIcon from "../assets/PhoneCallIcon.svg";
import { Link } from "react-router-dom";

function InquiriesButton() {
  const { t } = useTranslation()
  return (
    <Link to="/Contact" className="w-full h-[2.5rem] custom-height-mq:h-[5rem] bg-[#D7D7D7] hover:bg-[#FBAD4A] text-[#5A5A5A] flex flex-row items-center gap-4 justify-center hover:cursor-pointer">
      <img
        src={PhoneCallIcon}
        alt="Inquiries"
        className="w-[1.09375rem] h-[1.09375rem] custom-height-mq:w-[1.225rem] custom-height-mq:h-[1.75rem]"
      />
      <div className="text-xs custom-height-mq:text-2xl font-bold leading-[40px]">
        {t("For purchase and inquiries, contact us")}
      </div>
    </Link>
  );
}

export default InquiriesButton;
