import { useState } from 'react';
import WhiteYoutubeIcon from '../assets/WhiteYoutubeIcon.png';
import RedYoutubeIcon from '../assets/RedYoutubeIcon.png';
import AboutUsYoutubeIcon from '../../About Us/assets/AboutUsYoutubeIcon.png';


interface YoutubeIconProbs {
  AboutUs?: boolean;
}

function YoutubeIcon({AboutUs} : YoutubeIconProbs) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className='absolute'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        src={isHovered ? RedYoutubeIcon : (AboutUs ? AboutUsYoutubeIcon : WhiteYoutubeIcon)}
        alt="Youtube"
        className={`${AboutUs ? "w-[3.623125rem] h-[2.534375rem] custom-height-mq:w-[9.67rem] custom-height-mq:h-[6.77rem]" : "w-[4.11125rem] h-[2.8775rem] custom-height-mq:w-[7.145rem] custom-height-mq:h-[5rem]" } hover:cursor-pointer`}
        loading="lazy"
      />
    </div>
  );
}

export default YoutubeIcon;
