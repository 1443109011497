import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedApplication: '1',
};

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    selectApplication: (state, action) => {
      state.selectedApplication = action.payload;
    },
  },
});

export const { selectApplication } = applicationsSlice.actions;
export default applicationsSlice.reducer;
