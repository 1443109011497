import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface BuildYourDreamHeaderProps {
    title :string;
}


function BuildYourDreamHeader({title}: BuildYourDreamHeaderProps) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);
    return (
      <div className={` ${currentLanguage === "ar" ? "rtl" : "ltr"} text-white text-xl custom-height-mq:text-5xl font-normal leading-16 text-center`}>
        {title}
      </div>
    );
  }
  
  export default BuildYourDreamHeader;