import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { usePostAwardsDataMutation } from '../../../store/apiService';
import ContactUsButton from "../../Main components/ContactUsButton";
import CalcModalButton from "../../Main components/Modals/CalcModalButton";
import NavigateTopButton from "../../Main components/NavigateTopButton";
import ChemaGroupBlueIcon from "../assets/ChemaGroupBlueIcon.svg";
import UnderlineHeader from "../../Main components/UnderlineHeader";
import AwardsHeader from "../AwardsHeader";
import InputLabelAwards from "../LoginToTheProgram/InputLabelAwards";
import SideBar from "../SideBar";
import TermsAndConditionsIcon from "../assets/TermsAndConditionsIcon.svg";
import PrizesIcon from "../assets/Prizes.svg";
import RegisterIcon from "../assets/RegisterIcon.svg";
import LoginIcon from "../assets/LoginIcon.svg";
import Header from "../../Main components/Header/Header";
import MobileBottomNavBar from "../../Main components/MobileBottomNavBar/MobileBottomNavBar";
import PagesTitle from "../../Main components/pagesTitle";
import AwardsChemaImage from "../assets/AwardsChemaImage.svg";
import { RootState } from '../../../store/store';
import { setUser } from '../../../store/userSlice';

export default function UpdateProfile() {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.user);

  const [formData, setFormData] = useState({
    national_id: '',
    job: '',
    name: '',
    phone_1: '',
    phone_2: '',
    state: '',
    address: '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        national_id: user.national_id,
        job: user.job,
        name: user.name,
        phone_1: user.phone_1,
        phone_2: user.phone_2,
        state: user.state,
        address: user.address,
      });
    }
  }, [user]);

  const [postAwardsData] = usePostAwardsDataMutation();

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const handleUpdateData = async () => {
    try {
      const response = await postAwardsData({ formType: 'update-profile', ...formData, id: user.id }).unwrap();
      const { data: { user: updatedUser } } = response;

      // Update user data in Redux store
      dispatch(setUser({ user: updatedUser }));
      message.success('Profile updated successfully');
    } catch (error) {
      message.error('Profile update failed: ' + (error instanceof Error ? error.message : 'Please enter valid data'));
    }
  };

  const BottomNavBarIcons = [
    TermsAndConditionsIcon,
    PrizesIcon,
    RegisterIcon,
    LoginIcon,
  ];
  const BottomNavBarTitles = [
    "الشروط والأحكام",
    "جوائز المسابقة",
    "التسجيل في البرنامج",
    "تسجيل الدخول",
  ];

  return (
    <div className="arabicLanguage relative flex flex-col min-h-screen">
      <Header Active="" awards={true} />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={"تعديل المعلومات الشخصية"}
        awards={true}
      />
      <ContactUsButton Awards={true} />
      <NavigateTopButton Awards={true} />
      <AwardsHeader />
      <div className="flex flex-row h-full overflow-hidden justify-center pt-0 custom-height-mq:pt-[6.25rem] items-start right-0 relative rtl">
        <SideBar />
        <div className="flex flex-col gap-6 justify-center items-center custom-height-mq:items-stretch custom-height-mq:gap-9 w-screen custom-height-mq:w-[62.125rem] py-0 custom-height-mq:py-[5rem] mr-[14rem]">
          <img
            src={AwardsChemaImage}
            alt="Awards"
            className="w-full object-cover h-[14rem] block custom-height-mq:hidden"
          />
          <div className="relative self-center top-[-3rem] custom-height-mq:top-[-3.5rem] block custom-height-mq:hidden h-full overflow-auto">
            <PagesTitle title="تعديل المعلومات الشخصية" Awards={true} />
          </div>
          <div className="w-fit self-center">
            <UnderlineHeader title="تعديل المعلومات الشخصية" Awards={true} />
          </div>
          <div className="flex flex-col items-center custom-height-mq:items-stretch flex-wrap gap-5">
            <div className="flex flex-col gap-3">
              <InputLabelAwards text="المهنة *" />
              <input
                name="job"
                value={formData.job}
                onChange={handleInputChange}
                className="w-[18.375rem] h-[1.75rem] custom-height-mq:w-full custom-height-mq:h-[3rem] bg-[#5A5A5A] bg-opacity-10 pr-[1rem]"
              />
            </div>
            <div className="flex flex-col custom-height-mq:flex-row w-full justify-between items-center">
              <div className="flex flex-col gap-3 w-full custom-height-mq:w-1/2">
                <InputLabelAwards text="رقم الهاتف 1 *" />
                <input
                  name="phone_1"
                  value={formData.phone_1}
                  onChange={handleInputChange}
                  className="w-[18.375rem] h-[1.75rem] custom-height-mq:w-[90%] custom-height-mq:h-[3rem] bg-[#5A5A5A] bg-opacity-10 pr-[1rem]"
                />
              </div>
              <div className="flex flex-col gap-3 w-full custom-height-mq:w-1/2">
                <InputLabelAwards text="رقم الهاتف 2 (اختياري)" />
                <input
                  name="phone_2"
                  value={formData.phone_2}
                  onChange={handleInputChange}
                  className="w-[18.375rem] h-[1.75rem] custom-height-mq:w-full custom-height-mq:h-[3rem] bg-[#5A5A5A] bg-opacity-10 pr-[1rem]"
                />
              </div>
            </div>
            <div className="flex flex-col custom-height-mq:flex-row w-full justify-between items-center">
              <div className="flex flex-col gap-3 w-full custom-height-mq:w-1/2">
                <InputLabelAwards text="العنوان *" />
                <input
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  className="w-[18.375rem] h-[1.75rem] custom-height-mq:w-[90%] custom-height-mq:h-[3rem] bg-[#5A5A5A] bg-opacity-10 pr-[1rem]"
                />
              </div>
              <div className="flex flex-col gap-3 w-full custom-height-mq:w-1/2">
                <InputLabelAwards text="المحافظة *" />
                <input
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  className="w-[18.375rem] h-[1.75rem] custom-height-mq:w-full custom-height-mq:h-[3rem] bg-[#5A5A5A] bg-opacity-10 pr-[1rem]"
                />
              </div>
            </div>
          </div>
          <div className="font-bold text-xs self-center custom-height-mq:self-auto custom-height-mq:text-base text-[#5A5A5A] arabicLanguage">
            الحقول التي بها علامة النجمة (*) هي حقول مطلوبة يجب ملؤها.
          </div>
          <div className="flex flex-row justify-center items-center gap-8 mt-[1rem]">
            <CalcModalButton
              title="حفظ التعديلات"
              bgColor="bg-[#FBAD4A]"
              TextColor="text-[#1C4585]"
              hoverBgColor="bg-[#1C4585]"
              hoverTextColor="text-white"
              Awards={true}
              isRegister={true}
              onClick={handleUpdateData}
            />
            <CalcModalButton
              title="إلغاء"
              bgColor="bg-[#D7D7D7]"
              TextColor="text-[#868686]"
              hoverBgColor="bg-[#1C4585]"
              hoverTextColor="text-white"
              Awards={true}
              isRegister={true}
            />
          </div>
          <img src={ChemaGroupBlueIcon} alt="Chema Group" className="w-[9.98625rem] h-[3.25rem] self-center my-[2rem] mb-[6rem] custom-height-mq:mb-0 custom-height-mq:mt-[7rem]" />
        </div>
      </div>
    </div>
  );
}

