import { useSelector } from 'react-redux';
import FilterIcon from './assets/FilterIcon.svg';
import { RootState } from '../../../store/store';

interface FilterHeaderProps{
    title: string;
}


function FilterHeader ({title} : FilterHeaderProps) {
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    
    return(
        <div className="flex flex-row items-center justify-start gap-2">
            <img src={FilterIcon} alt="Filter title" className={`w-[0.9375rem] h-[0.9375rem] ${currentLanguage === "ar" ? "mt-[0.3rem]" : ""} `} />
            <div className="text-xl font-bold tracking-normal text-left text-[#5A5A5A]">
                {title}
            </div>
        </div>
    )
}

export default FilterHeader;