interface AwardsHeaderProps {
    title : string;
    CompanyRights?: boolean;
}

function AwardsHeader ({title , CompanyRights} : AwardsHeaderProps) {
    return (
        <div className={`font-bold text-sm custom-height-mq:text-2xl arabicLanguage ${CompanyRights ? "text-[#1EBAE5]" : "text-[#1C4585]"}`}>{title}</div>
    )
}

export default AwardsHeader;