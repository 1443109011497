import PrizesCardPoint from "./PrizesCardPoint";

interface PrizesProductCardProbs {
    title : string;
    points : number;
    image : string;
}


function PrizesProductCard({title , points , image} : PrizesProductCardProbs) {
    return(
        <div className="relative w-[10rem] h-[13.699rem] custom-height-mq:w-[17.0625rem] custom-height-mq:h-[23.375rem] flex flex-col pt-[1rem] items-center justify-center border-2 border-[#FBAD4A]">
            <PrizesCardPoint Points={`${points} نقطة`} />
            <img src={image} alt="Filter" className="w-full h-fit" />
            <div className="font-[300] text-sm custom-height-mq:text-xl text-[#1C4585] arabicLanguage">{title}</div>
        </div>
    )
}

export default PrizesProductCard;