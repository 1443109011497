import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/store';
import {
  useGetPageDataQuery,
  useGetApplicationDataQuery,
} from '../../store/apiService';
import ContactUsButton from '../Main components/ContactUsButton';
import Filter from '../Main components/Filter/Filter';
import Header from '../Main components/Header/Header';
import MainFooter from '../Main components/MainFooter/MainFooter';
import MainHeader from '../Main components/MainHeader/MainHeader';
import NavigateTopButton from '../Main components/NavigateTopButton';
import PagesTitle from '../Main components/pagesTitle';
import WaterproofingApplication from './Components/WaterproofingApplications';
import Difference from './Components/Difference/Difference';
import FeaturesBenefits from './Components/FeaturesBenefits';
import Accordion from '../Main components/Accordion';
import AccordionContent from './Components/AccordionContent';
import MobileBottomNavBar from '../Main components/MobileBottomNavBar/MobileBottomNavBar';
import HouseIcon from '../Main components/MobileBottomNavBar/assets/HomeIcon.svg';
import AppIcon from '../Main components/MobileBottomNavBar/assets/AppsIcon.svg';
import ProductIcon from '../Main components/MobileBottomNavBar/assets/ProductIcon.svg';
import ContactIcon from '../Main components/MobileBottomNavBar/assets/ContactIcon.svg';
import MoreIcon from '../Main components/MobileBottomNavBar/assets/MoreIcon.svg';

function Applications() {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const selectedApplicationId = useSelector(
    (state: RootState) => state.applications.selectedApplication
  );
  const { t, i18n } = useTranslation();
  const [selectedProductId, setSelectedProductId] = useState(
    selectedApplicationId
  );
  const { data, error, isLoading, refetch } = useGetPageDataQuery(
    'Application',
    {
      skip: false,
    }
  );
  const { data: applicationData, refetch: refetchApplicationData } =
    useGetApplicationDataQuery(selectedProductId, {
      skip: !selectedProductId,
    });

  useEffect(() => {
    refetch();
  }, [currentLanguage, i18n, refetch, error]);

  useEffect(() => {
    {
      data && setSelectedProductId(data['application'].id);
    }
  }, [data]);

  useEffect(() => {
    if (selectedProductId) {
      refetchApplicationData();
    }
  }, [selectedProductId, refetchApplicationData]);

  useEffect(() => {
    if (selectedApplicationId) {
      setSelectedProductId(selectedApplicationId);
    }
  }, [selectedApplicationId]);

  const BottomNavBarIcons = [
    HouseIcon,
    AppIcon,
    ProductIcon,
    ContactIcon,
    MoreIcon,
  ];
  const BottomNavBarTitles = [
    t('Home'),
    t('Applications'),
    t('Products'),
    t('Contact Us'),
    t('More'),
  ];

  const ApplicationsFilter = useMemo(() => {
    if (!data) return { sections: [] };

    const sections = data.sidebar.map(
      (category: {
        categoryName: any | string | string[];
        application: any[];
      }) => ({
        name: t(category.categoryName),
        subItems: category.application.map(
          (app: { id: number; title: any | string | string[] }) => ({
            id: app.id.toString(),
            title: t(app.title),
          })
        ),
      })
    );

    return { sections };
  }, [data, t]);

  return (
    data &&
    applicationData && (
      <div className="flex flex-col items-center">
        <Header Active="Applications" />
        <MobileBottomNavBar
          icons={BottomNavBarIcons}
          titles={BottomNavBarTitles}
          active={t('Applications')}
        />
        <NavigateTopButton />
        <ContactUsButton />
        <MainHeader title={t('APPlications')} page={t('Applications')} />
        <div
          className={`w-[20.964375rem] custom-height-mq:w-[77rem] flex flex-col items-center custom-height-mq:flex-row custom-height-mq:items-start justify-center gap-[1rem] py-[1rem] custom-height-mq:gap-[5rem] custom-height-mq:py-[4rem] ${
            currentLanguage === 'ar' ? 'rtl' : 'ltr'
          }`}
        >
          <Filter
            title={t('Applications')}
            items={ApplicationsFilter}
            selectedProductId={selectedProductId}
            onFilterChange={setSelectedProductId}
          />
          <div className="flex flex-col max-w-[20.964375rem] custom-height-mq:max-w-[55.6875rem] items-center justify-center gap-11">
            <div className="relative flex flex-col items-center justify-center">
              <img
                src={
                  applicationData['application']['app_page_header_image'][0][
                    'image'
                  ]
                }
                alt="Waterproofing"
                className="w-[56rem] h-[9.3125rem] custom-height-mq:h-[18.625rem]"
              />
              <div className="absolute bottom-[0rem] custom-height-mq:bottom-[-3rem]">
                <PagesTitle title={applicationData['application']['title']} />
              </div>
            </div>
            {applicationData['application']['videos'][0] ? (
              <div className="flex items-center justify-center w-[18.4375rem] h-[10.4925rem] custom-height-mq:w-[49.3125rem] custom-height-mq:h-[28.0625rem] custom-height-mq:mt-[4rem]">
                <iframe
                  width="100%"
                  height="100%"
                  src={applicationData['application']['videos'][0]['video_url']}
                  title="About Chema Foam"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : (
              <div className="mb-4" />
            )}
            <WaterproofingApplication
              title={applicationData['application']['title']}
              description={applicationData['application']['description']}
            />
            <Difference
              comparison={
                applicationData['application']['application_comparison']
              }
            />
            <FeaturesBenefits
              header={
                i18n.language === 'ar'
                  ? `${t('Application Features')} ${
                      applicationData['application']['title']
                    }`
                  : `${applicationData['application']['title']} ${t(
                      'Application Features'
                    )}`
              }
              feature={true}
              bgColor={'bg-[#5A5A5A] bg-opacity-10'}
              items={applicationData['application']['featurs']}
              image={
                applicationData['application']['featurs_image'][0]['image']
              }
            />
            <FeaturesBenefits
              header={
                i18n.language === 'ar'
                  ? `${t('Application Benefits')} ${
                      applicationData['application']['title']
                    }`
                  : `${applicationData['application']['title']} ${t(
                      'Application Benefits'
                    )}`
              }
              feature={false}
              bgColor={'bg-[#1EBAE5] bg-opacity-10'}
              items={applicationData['application']['benefits']}
              image={
                applicationData['application']['benefits_image'][0]['image']
              }
            />
            <div className="flex flex-col w-full gap-3 custom-height-mq:gap-6 items-center justify-center">
              <div className="hidden custom-height-mq:block text-2xl font-bold text-[#1C4585] text-center mb-[1rem]">
                {t('Application Places')}
              </div>
              {applicationData['application']['application_places'].map(
                (item: any) => (
                  <Accordion
                    key={item.id}
                    Icon={item.icon}
                    title={item.name}
                    Content={
                      <AccordionContent
                        context={item.descrition}
                        image={item.image}
                        recommendedProducts={
                          applicationData['recommended_products']
                        }
                      />
                    }
                  />
                )
              )}
            </div>
          </div>
        </div>
        <MainFooter />
      </div>
    )
  );
}

export default Applications;
