import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface DescContentProbs {
  description : string;
  descriptionAdvantage : Array<any>;
  image : string;
}

function DescContent({description, descriptionAdvantage , image} : DescContentProbs) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);

  return (
    <div className="flex flex-col w-[18.4375rem] custom-height-mq:w-[50rem] gap-4 justify-center items-center">
      <div className="text-xs custom-height-mq:text-base text-justify font-normal text-[#1C4585] capitalize">
        {description}
      </div>
      <ul className={`text-[0.65rem] leading-[0.9rem] custom-height-mq:text-sm text-justify capitalize font-normal list-disc ${currentLanguage === "ar" ? "mr-[1.5rem] custom-height-mq:mr-[2rem]":"ml-[1.5rem] custom-height-mq:ml-[2rem]"} self-baseline text-[#5A5A5A]`}>
      {descriptionAdvantage.map((item) => (
          <li key={item.id}>{item.content}</li>  
        ))}
      </ul>
      <div className="flex flex-col gap-3 custom-height-mq:gap-0 custom-height-mq:flex-row justify-between w-full">
        <img src={image} alt="Description" className="w-full h-full custom-height-mq:w-[49rem] custom-height-mq:h-[15.5rem]" />
      </div>
    </div>
  );
}

export default DescContent;
