import { useSelector } from "react-redux";
import ContactUsButton from "../Main components/ContactUsButton";
import Filter from "../Main components/Filter/Filter";
import Header from "../Main components/Header/Header";
import MainFooter from "../Main components/MainFooter/MainFooter";
import MainHeader from "../Main components/MainHeader/MainHeader";
import NavigateTopButton from "../Main components/NavigateTopButton";
import ProductDocumentsCard from "./components/ProductDocumentsCard";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import MobileBottomNavBar from "../Main components/MobileBottomNavBar/MobileBottomNavBar";
import HouseIcon from "../Main components/MobileBottomNavBar/assets/HomeIcon.svg";
import AppIcon from "../Main components/MobileBottomNavBar/assets/AppsIcon.svg";
import ProductIcon from "../Main components/MobileBottomNavBar/assets/ProductIcon.svg";
import ContactIcon from "../Main components/MobileBottomNavBar/assets/ContactIcon.svg";
import MoreIcon from "../Main components/MobileBottomNavBar/assets/MoreIcon.svg";
import { useEffect, useMemo, useState } from "react";
import { useGetPageDataQuery } from "../../store/apiService";

function Download() {
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  const { t } = useTranslation();
  const { data, error, isLoading, refetch } = useGetPageDataQuery("Downloads", {
    skip: false,
  });
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);

  useEffect(() => {
    refetch();
  }, [currentLanguage, refetch, error]);

  useEffect(() => {
    if (data) {
      const ids = data.products.map((product: any) => product.id.toString());
      setSelectedProductIds(ids);
    }
  }, [data]);

  const handleFilterChange = (id: string) => {
    setSelectedProductIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id]
    );
  };

  const BottomNavBarIcons = [
    HouseIcon,
    AppIcon,
    ProductIcon,
    ContactIcon,
    MoreIcon,
  ];
  const BottomNavBarTitles = [
    t("Home"),
    t("Applications"),
    t("Products"),
    t("Contact Us"),
    t("More"),
  ];

  const ProductsFilter = useMemo(() => {
    if (!data) return { sections: [] };

    const sections = data.sidebar.map((category: { title: any | string | string[]; products: any[]; }) => ({
      name: t(category.title),
      subItems: category.products.map((app: { id: number; title: any | string | string[]; }) => ({
        id: app.id.toString(),
        title: t(app.title),
      })),
    }));

    return { sections };
  }, [data, t]);

  const filteredProducts = useMemo(() => {
    return data
      ? data.products.filter((product: any) =>
          selectedProductIds.includes(product.id.toString())
        )
      : [];
  }, [data, selectedProductIds]);

  return data && (
    <div className="flex flex-col justify-center items-center">
      <Header Active='Downloads' />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={t("Downloads")}
      />
      <NavigateTopButton />
      <ContactUsButton />
      <MainHeader title={t('Products Documents')} page={t('Downloads')} />
      <div className={`w-[20.964375rem] custom-height-mq:w-[77rem] flex flex-col custom-height-mq:flex-row items-start justify-center gap-[1rem] custom-height-mq:gap-[5rem] py-[1rem] custom-height-mq:py-[4rem] ${currentLanguage === "ar" ? "rtl" : "ltr"} `}>
        <Filter
          title={t('Filter')}
          items={ProductsFilter}
          checkbox={true}
          selectedProductIds={selectedProductIds}
          onFilterChange={handleFilterChange}
        />
        <div className="flex flex-col gap-4 custom-height-mq:gap-10 custom-height-mq:gap-y-10 w-full custom-height-mq:flex-row items-center flex-wrap">
          {filteredProducts.map((item: any) => (
            <div key={item.id} className="custom-height-mq:w-[30%]">
              <ProductDocumentsCard
                subtitle={item.subtitle}
                title={item.title}
                weight={item.weight}
                unitOfMeasure={item.unit_of_measure}
                image={item['card_image']['image']}
                pdf={item.pdf}
              />
            </div>
          ))}
        </div>
      </div>
      <MainFooter />
    </div>
  );
}

export default Download;
