import { useState } from 'react';
import CalcModal from '../../../Main components/Modals/CalcModal';
import DownloadModal from '../../../Main components/Modals/DownloadModal';
import InformationModal from '../../../Main components/Modals/InformationModal';
import VideoModal from '../../../Main components/Modals/VideoModal';

interface InformationCardProps {
    icon: string;
    ModalType : string;
    title?: string;
    subtitle?: string;
    description?: string;
    weight?: number;
    unitOfMeasure?: string;
    coverageRate ?: number;
    coverageRateUnit ?: string;
    howToApply ?: string;
    cardImage ?: string;
    descriptionAdvantages ?: Array<any>;
    pdf?: Array<any>;
    places?: Array<any>;
    video?: string;
    productID?: number;
}

function InformationCard({icon,ModalType , title , subtitle , description , weight , unitOfMeasure , coverageRate , coverageRateUnit , howToApply , cardImage ,descriptionAdvantages , pdf , places , video , productID} : InformationCardProps){
    const [modalVisible, setModalVisible] = useState(false);
    const handleOpenModal = () => {
        setModalVisible(true);
      };
    
      const handleCloseModal = () => {
        setModalVisible(false);
      };

    const ModalContent = () => {
        if (ModalType === 'Calc') {
          return <CalcModal visible={modalVisible} onCancel={handleCloseModal} cardImage={cardImage} title={title} subtitle={subtitle} coverageRate={coverageRate} coverageRateUnit={coverageRateUnit} weight={weight} unitOfMeasure={unitOfMeasure} />;
        } else if (ModalType === 'Download') {
          return <DownloadModal visible={modalVisible} onCancel={handleCloseModal} cardImage={cardImage} title={title} subtitle={subtitle} unitOfMeasure={unitOfMeasure} weight={weight} pdf={pdf} />;
        } else if (ModalType === 'Information' && productID) {
          return <InformationModal visible={modalVisible} productID={productID} onCancel={handleCloseModal} howToApply={howToApply} cardImage={cardImage} title={title} subtitle={subtitle} description={description} unitOfMeasure={unitOfMeasure} weight={weight} descriptionAdvantages={descriptionAdvantages} places={places} />;
        } else if (ModalType === 'Video') {
          return <VideoModal visible={modalVisible} onCancel={handleCloseModal} video={video} />;
        } else {
          return null;
        }
      };


    return(
        <>
        <div className="flex justify-center hover:cursor-pointer hover:bg-[#FBAD4A] items-center w-[2rem] h-[2rem] bg-[#D7D7D7]"
        onClick={handleOpenModal}>
            <img src={icon} alt="Information" loading="lazy" />
        </div>
        <ModalContent />
        </>
    )
}


export default InformationCard;