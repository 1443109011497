import { useSelector } from 'react-redux';
import RightBlueIcon from '../assets/RightBlueIcon.svg';
import { RootState } from '../../../store/store';


interface VideosHeaderProbs{
  first: string;
  rest : string;
  contact ?: boolean;
  subscription?: boolean;
}

function VideosHeader ({first,rest , contact , subscription} : VideosHeaderProbs) {
    const currentLanguage = useSelector(
        (state: RootState) => state.language.language
      );
    return(
        <div className={`flex flex-row sticky ${contact ? "self-start":"self-center"} custom-height-mq:self-start items-center justify-center gap-2 custom-height-mq:gap-4`}>
            <img src={RightBlueIcon} alt="Header" className={`w-[0.7rem] h-[0.7rem] mb-[0.13rem] custom-height-mq:mb-0 custom-height-mq:w-[0.65625rem] custom-height-mq:h-[1.25rem] ${contact? "" : "hidden custom-height-mq:block"}  ${currentLanguage === "ar" ? "rotate-180" : ""}`} />
            <div className={`flex flex-row text-[#1C4585] gap-2 custom-height-mq:gap-3 ${currentLanguage === "ar"  ? (contact ? "rtl mb-[0.6rem]" : "ltr mb-[0.6rem]") : "ltr"} `}>
            <div className={`font-bold  ${contact?(subscription? "text-xs" : "text-sm"):"text-xl"} custom-height-mq:text-4xl`}>{first}</div>
            <div className={`font-normal ${contact?(subscription? "text-xs" : "text-sm"):"text-xl"} custom-height-mq:text-4xl`}>{rest}</div>
            </div>
        </div>
    )
}

export default VideosHeader;