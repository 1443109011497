interface WhoWeAreContentProps {
    title: string;
  }
  
  function WhoWeAreContent({ title }: WhoWeAreContentProps) {
    const capitalizedTitle = title
    ? title
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : '';
    return (
      <div className="text-justify text-xs custom-height-mq:text-center custom-height-mq:text-base font-normal custom-height-mq:leading-7 tracking-normal text-[#5A5A5A] w-[18.5625rem] custom-height-mq:w-[67.0125rem]">
        {capitalizedTitle}
      </div>
    );
  }
  
  export default WhoWeAreContent;
  