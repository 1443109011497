import CalcModalButton from "../../Main components/Modals/CalcModalButton";
import { usePostFormDataMutation  } from "../../../store/apiService";
import { useTranslation } from "react-i18next";
import { message } from 'antd';
import { useEffect, useState } from "react";

interface ContactUsContentProbs {
  endpoint: string;
}

function ContactUsContent({endpoint} : ContactUsContentProbs) {
  const { t } = useTranslation();
  const [submitForm , { isLoading: isSubscribing, isSuccess, isError , error }] = usePostFormDataMutation();
  const [formData, setFormData] = useState({
    message: '',
    company: '',
    first_name: '',
    last_name: '',
    street: '',
    country: '',
    email: '',
    phone: '',
    postal_code: '',
    city: '',
    fax: ''
  });

  useEffect(() => {
    let errorCount = 0;
    if (error && 'data' in error) {
      const errorData = (error as { data: { errors: { [key: string]: string[] } } }).data;
      const errors = errorData.errors;
      errorCount = Object.keys(errors).reduce((count, field) => count + errors[field].length, 0);
    }

    if (isSuccess) {
      message.success(t('Form submitted!'));
    } else if (isError) {
      if (error && 'data' in error) {
        const errorData = (error as { data: { errors: { [key: string]: string[] } } }).data;
        const errors = errorData.errors;
        Object.keys(errors).forEach(field => {
          errors[field].forEach((msg: string) => {
            message.error(t(msg));
          });
        });
      } else {
        message.error(t('Error.'));
      }
    }
  }, [isSuccess, isError, error, t]);
  

  const handleChange = (event : any) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    await submitForm({...formData, formType: endpoint});
  };

  const handleReset = () => {
    setFormData({
      message: '',
      company: '',
      first_name: '',
      last_name: '',
      street: '',
      country: '',
      email: '',
      phone: '',
      postal_code: '',
      city: '',
      fax: ''
    });
  };

  return (
    <div className="w-full gap-1 custom-height-mq:gap-7 my-0 custom-height-mq:my-[1rem] flex flex-col justify-center items-start">
      <div className="text-[#868686] font-bold text-sm custom-height-mq:text-3xl">
        {t("Contact Form")}
      </div>
      <div className="flex flex-col custom-height-mq:flex-row w-full justify-between min-h-[18.625rem]">
        <div className="flex flex-col justify-start w-full custom-height-mq:w-[48%] gap-1">
          <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
            {t("Your Message *")}
          </div>
          <textarea name="message" value={formData.message} onChange={handleChange} className="bg-[#5A5A5A] p-4 bg-opacity-10 h-[16.5rem] w-full" />
        </div>
        <div className="flex flex-col justify-between w-full custom-height-mq:w-[48%]">
          <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
            {t("Company *")}
          </div>
          <input name="company" value={formData.company} onChange={handleChange} className="bg-[#5A5A5A] px-3 bg-opacity-10 h-[1.5rem] custom-height-mq:h-[3rem] w-full" />
          <div className="flex flex-row flex-wrap justify-between">
            <div className="flex flex-col">
              <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
                {t("First Name *")}
              </div>
              <input name="first_name" value={formData.first_name} onChange={handleChange} className="bg-[#5A5A5A] px-3  bg-opacity-10 h-[1.5rem] custom-height-mq:h-[3rem] w-full custom-height-mq:w-[15.6rem]" />
            </div>
            <div className="flex flex-col">
              <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
                {t("Last Name *")}
              </div>
              <input name="last_name" value={formData.last_name} onChange={handleChange} className="bg-[#5A5A5A] px-3 bg-opacity-10 h-[1.5rem] custom-height-mq:h-[3rem] w-full custom-height-mq:w-[15.6rem]" />
            </div>
          </div>
          <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
            {t("Street Number")}
          </div>
          <input name="street" value={formData.street} onChange={handleChange} className="bg-[#5A5A5A] px-3 bg-opacity-10 h-[1.5rem] custom-height-mq:h-[3rem] w-full" />
        </div>
      </div>
      <div className="flex flex-row flex-wrap justify-between w-full">
        <div className="flex flex-col">
          <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
            {t("Postal Code")}
          </div>
          <input name="postal_code" value={formData.postal_code} onChange={handleChange} className="bg-[#5A5A5A] px-3 bg-opacity-10 h-[1.5rem] custom-height-mq:h-[3rem] w-full custom-height-mq:w-[15.8125rem]" />
        </div>
        <div className="flex flex-col">
          <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
            {t("City")}
          </div>
          <input name="city" value={formData.city} onChange={handleChange} className="bg-[#5A5A5A] bg-opacity-10 px-3 h-[1.5rem] custom-height-mq:h-[3rem] w-full custom-height-mq:w-[21.6875rem]" />
        </div>
        <div className="flex flex-col">
          <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
            {t("Country *")}
          </div>
          <input name="country" value={formData.country} onChange={handleChange} className="bg-[#5A5A5A] bg-opacity-10 px-3 h-[1.5rem] custom-height-mq:h-[3rem] w-full custom-height-mq:w-[21.9375rem]" />
        </div>
      </div>
      <div className="flex flex-row flex-wrap justify-between w-full">
        <div className="flex flex-col">
          <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
            {t("Phone *")}
          </div>
          <input name="phone" value={formData.phone} onChange={handleChange} className="bg-[#5A5A5A] bg-opacity-10 px-3 h-[1.5rem] custom-height-mq:h-[3rem] w-full custom-height-mq:w-[15.8125rem]" />
        </div>
        <div className="flex flex-col">
          <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
            {t("Fax")}
          </div>
          <input name="fax" value={formData.fax} onChange={handleChange} className="bg-[#5A5A5A] bg-opacity-10 h-[1.5rem] px-3 custom-height-mq:h-[3rem] w-full custom-height-mq:w-[21.6875rem]" />
        </div>
        <div className="flex flex-col">
          <div className="font-normal text-xs custom-height-mq:text-xl leading-[2rem] text-[#1C4585]">
            {t("Email *")}
          </div>
          <input name="email" value={formData.email} onChange={handleChange} className="bg-[#5A5A5A] bg-opacity-10 h-[1.5rem] px-3 custom-height-mq:h-[3rem] w-full custom-height-mq:w-[21.9375rem]" />
        </div>
      </div>
      <div className="flex flex-row mt-5 custom-height-mq:mt-0 self-end gap-5">
        <CalcModalButton
          bgColor={"bg-[#D7D7D7]"}
          hoverBgColor={"bg-[#1C4585]"}
          TextColor={"text-[#868686]"}
          hoverTextColor={"text-white"}
          title={t("Reset")}
          onClick={handleReset}
        />
        <CalcModalButton
          bgColor={"bg-[#FBAD4A]"}
          hoverBgColor={"bg-[#1C4585]"}
          TextColor={"text-[#1C4585]"}
          hoverTextColor={"text-white"}
          title={t("Submit Form")}
          ContactUs={true}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

export default ContactUsContent;
