import ContactUsButton from "../../Main components/ContactUsButton";
import NavigateTopButton from "../../Main components/NavigateTopButton";
import PagesTitle from "../../Main components/pagesTitle";
import UnderlineHeader from "../../Main components/UnderlineHeader";
import AwardsChemaImage from "../assets/AwardsChemaImage.svg";
import AwardsHeader from "../AwardsHeader";
import SideBar from "../SideBar";
import AwardsCard from "./AwardsCard/AwardsCard";
import AwardsContentText from "./AwardsCard/AwardsContentText";
import AwardsProductCard from "./AwardsProductCard/AwardsProductCard";
import ChemaGroupBlueIcon from "../assets/ChemaGroupBlueIcon.svg";
import MobileBottomNavBar from "../../Main components/MobileBottomNavBar/MobileBottomNavBar";
import TermsAndConditionsIcon from "../assets/TermsAndConditionsIcon.svg";
import PrizesIcon from "../assets/Prizes.svg";
import RegisterIcon from "../assets/RegisterIcon.svg";
import LoginIcon from "../assets/LoginIcon.svg";
import Header from "../../Main components/Header/Header";
import { useGetAwardsDataQuery } from "../../../store/apiService";


function TermsAndConditions() {
  const { data: awardsData, error, isLoading } = useGetAwardsDataQuery('chema-products-point');
  const BottomNavBarIcons = [
    TermsAndConditionsIcon,
    PrizesIcon,
    RegisterIcon,
    LoginIcon,
  ];
  const BottomNavBarTitles = [
    "الشروط والأحكام",
    "جوائز المسابقة",
    "التسجيل في البرنامج",
    "تسجيل الدخول",
  ];
  return awardsData && (
    <div className="arabicLanguage relative flex flex-col min-h-screen">
      <Header Active="" awards={true} />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={"الشروط والأحكام"}
        awards={true}
      />
      <ContactUsButton Awards={true} />
      <NavigateTopButton Awards={true} />
      <AwardsHeader />
      <div className="flex flex-row h-full overflow-hidden justify-center pt-0 custom-height-mq:pt-[6.25rem] items-start right-0 relative">
        <SideBar Active="الشروط  والأحكام" />
        <div className="flex flex-col w-screen custom-height-mq:w-[62.125rem] py-0 custom-height-mq:py-[5rem] mr-[14rem]">
          <img
            src={AwardsChemaImage}
            alt="Awards"
            className="w-full object-cover h-[14rem]"
          />
          <div className="relative self-center top-[-1.5rem] custom-height-mq:top-[-3.5rem] h-full overflow-auto">
            <PagesTitle title="برنامج جمع النقاط" Awards={true} />
          </div>
          <div className="flex flex-col gap-9">
            <AwardsCard
              header={"ما هوا برنامج جمع النقاط؟"}
              bgColor="bg-[#1EBAE51A] bg-opacity-10"
              content={
                <AwardsContentText text="عبارة عن مسابقة على هيئة كروت خدش موجودة في بعض المنتجات يتم ادخال الرقم الموجود على حساب خاص بالفني ويتم تجميع النقاط مع شراء المنتجات واستبدالها بهدايا." />
              }
            />
            <AwardsCard
              header={"ما هي طريقة الإشتراك؟"}
              bgColor="bg-[#FBAD4A] bg-opacity-10"
              content={
                <ol className="list-decimal mr-[1.5rem]">
                  <li>
                    <AwardsContentText text="الدخول على الرابط (*) وتسجيل الاسم ورقم البطاقة ورقم التليفون." />
                  </li>
                  <li>
                    <AwardsContentText text="خدش الكارت وادخال رقم الكارت وتجميع النقاط." />
                  </li>
                  <li>
                    <AwardsContentText text="عند الدخول مرة اخرى يتم الدخول على اللينك السابق وادخال رقم البطاقة ." />
                  </li>
                  <li>
                    <AwardsContentText text="يمكن الاستعلام عن عدد النقاط المجمعة ومعرفة الهدايا من خلال نفس الرابط." />
                  </li>
                </ol>
              }
            />
            <AwardsCard
              header={"ما هي طريقة الاستبدال؟"}
              bgColor="bg-[#1EBAE51A] bg-opacity-10"
              content={
                <ol className="list-decimal mr-[1.5rem]">
                  <li>
                    <AwardsContentText text="عند وصول العميل الى عدد النقاط المطلوبة للهدية يتم التواصل مع الارقام الخاصة بالمسابقة لتحديد موعد استلام الهدية خلال 30 يوم من الاتصال ويتم خصم النقاط من رصيد العميل على حسابة الخاص على الرابط السابق." />
                  </li>
                  <li>
                    <AwardsContentText text="يتم الاستبدال من خلال فروع الشركة او مراكز التلوين  المنتشرة على مستوى الجمهورية ويتم التنسيق مع ادارة المسابقة بموعد ومكان الاستلام." />
                  </li>
                </ol>
              }
            />
            <div className="flex flex-col justify-center items-center gap-11 mt-[1.5rem]">
              <div className="w-[18.4375rem] text-center self-center custom-height-mq:w-fit">
                <UnderlineHeader
                  title="العبوات التي تحتوي على كروت الخدش وعدد النقاط الموجودة في كل عبوة"
                  Awards={true}
                />
              </div>
              <div className="w-[20.875rem] custom-height-mq:w-[55.6875rem] grid grid-cols-2 custom-height-mq:grid-cols-3 gap-5 custom-height-mq:gap-11">
                {awardsData.chema_products_point.map((product : any) => <AwardsProductCard key={product.id} title={product.title} points={product.awards_point} image={product['card_image'].image} />)}
                
              </div>
            </div>
            <AwardsCard
              header={"شروط واحكام عامة:"}
              bgColor="bg-[#FBAD4A] bg-opacity-10"
              content={
                <ol className="list-decimal mr-[1.5rem]">
                  <li>
                    <AwardsContentText text="لا يحق لجميع موظفين الشركة او اقاربهم الاشتراك في المسابقة ." />
                  </li>
                  <li>
                    <AwardsContentText text="يحق للعميل الاستمرار في المسابقة حتى بعد حصوله على الهدية والاستمرار في حساب النقاط طوال فترة المسابقة." />
                  </li>
                  <li>
                    <AwardsContentText text="يحق للشركة استبدال ماركة الهدية المطلوبة حسب التواجد في السوق." />
                  </li>
                  <li>
                    <AwardsContentText text="يتم صرف الهدايا للافراد باسمائهم وبياناتهم الشخصية الموجودة على الموقع الخاص بالشركة والتي تم عمل الحساب بها ولا يتم صرفها من خلال توكيل او اي احد من افراد العائلة." />
                  </li>
                  <li>
                    <AwardsContentText text="تبدأ المسابقة في 15 اغسطس 2020 وتنتهي في 14 اغسطس 2023 ويحق للشركة مد المسابقة او انهائها كما يترائى لها." />
                  </li>
                  <li>
                    <AwardsContentText text="يحق للشركة استبدال عدد النقاط الموجودة في اي منتج او اضافة او حذف منتجات من المسابقة طوال فترة المسابقة." />
                  </li>
                  <li>
                    <AwardsContentText text="للشركة الحق في تصوير الفائزين لاستغلالها في اي مواد دعائية ويعد اشتراك العميل في موقع الشركة موافقة ضمنية منه على ذلك." />
                  </li>
                  <li>
                    <AwardsContentText text="في حالة تلف الكارت يتم التوجه الى اقرب فرع او مركز تلوين وتسليم الكارت ويتم اضافة الكارت في حالة عدم شحنه من قبل على رصيد المشترك." />
                  </li>
                </ol>
              }
            />
            <AwardsCard
              CompanyRights={true}
              header={
                "يحق للشركه تعديل عدد النقاط لكل هديه ونوع الهديه فى أى وقت دون أى إنذار مسبق"
              }
              bgColor="bg-[#1EBAE51A] bg-opacity-10"
              content={
                <div className="font-bold text-sm custom-height-mq:text-2xl arabicLanguage text-justify text-[#1C4585]">
                  كروت النقاط الموجودة في العبوات خاصة بالفني المستخدم للعبوة
                  فقط وغير قابلة للتداول او البيع او الشراء وفي حالة اكتشاف ذلك
                  يتم ايقاف المشترك فورا وللشركة الحق في اتخاذ كافة الاجراءات
                  التي تحفظ حقوقها حال اكتشاف اي تلاعب
                </div>
              }
            />
            <img
              src={ChemaGroupBlueIcon}
              alt="Chema Group"
              className="w-[9.98625rem] h-[3.25rem] self-center my-[2rem] mb-[6rem] custom-height-mq:mb-0 custom-height-mq:mt-[7rem]"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
