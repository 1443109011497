import { useState } from "react";
import PDFIcon from "../../Home/assets/PdfIcon.svg";
import { ReactComponent as BluePDFIcon } from "../../Home/assets/BluePdfIcon.svg";
import DashedDownloadIcon from "../../Home/assets/GreyDashedDownloadIcon.svg";
import { ReactComponent as BlueDashedDownloadIcon } from "../../Home/assets/BlueDashedDownloadIcon.svg";

interface DownloadButtonProps {
  title: string;
  onClick : () => void;
}

function DownloadButton({ title , onClick }: DownloadButtonProps) {
  const [isHovered, setHovered] = useState(false);

  return (
    <div
      className={`w-[16.875rem] h-[2.25rem] custom-height-mq:w-[44.75rem] custom-height-mq:h-[3.5rem] gap-5 hover:cursor-pointer flex justify-center items-center text-xs custom-height-mq:text-lg font-bold leading-2 bg-[#D7D7D7] text-[#5A5A5A] hover:text-[#1C4585] hover:bg-[#FBAD4A]`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      {!isHovered ? (
        <img
          src={PDFIcon}
          alt="PDF"
          className="w-[0.875rem] h-[0.875rem] custom-height-mq:w-[1.3125rem] custom-height-mq:h-[1.458125rem]"
        />
      ) : (
        <BluePDFIcon className="w-[0.875rem] h-[0.875rem] custom-height-mq:w-[1.3125rem] custom-height-mq:h-[1.458125rem]" />
      )}
      {title}
      {!isHovered ? (
        <img
          src={DashedDownloadIcon}
          alt="PDF"
          className="w-[0.875rem] h-[0.875rem] custom-height-mq:w-[1.3125rem] custom-height-mq:h-[1.458125rem]"
        />
      ) : (
        <BlueDashedDownloadIcon className="w-[0.875rem] h-[0.875rem] custom-height-mq:w-[1.3125rem] custom-height-mq:h-[1.458125rem]" />
      )}
    </div>
  );
}

export default DownloadButton;
