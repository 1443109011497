import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../store/store';
import {
  useGetPageDataQuery,
  useGetSystemsDataQuery,
} from '../../store/apiService';
import AccordionContent from '../Applications/Components/AccordionContent';
import Difference from '../Applications/Components/Difference/Difference';
import FeaturesBenefits from '../Applications/Components/FeaturesBenefits';
import WaterproofingApplication from '../Applications/Components/WaterproofingApplications';
import Accordion from '../Main components/Accordion';
import ContactUsButton from '../Main components/ContactUsButton';
import Filter from '../Main components/Filter/Filter';
import Header from '../Main components/Header/Header';
import MainFooter from '../Main components/MainFooter/MainFooter';
import MainHeader from '../Main components/MainHeader/MainHeader';
import NavigateTopButton from '../Main components/NavigateTopButton';
import PagesTitle from '../Main components/pagesTitle';
import MobileBottomNavBar from '../Main components/MobileBottomNavBar/MobileBottomNavBar';
import HouseIcon from '../Main components/MobileBottomNavBar/assets/HomeIcon.svg';
import AppIcon from '../Main components/MobileBottomNavBar/assets/AppsIcon.svg';
import ProductIcon from '../Main components/MobileBottomNavBar/assets/ProductIcon.svg';
import ContactIcon from '../Main components/MobileBottomNavBar/assets/ContactIcon.svg';
import MoreIcon from '../Main components/MobileBottomNavBar/assets/MoreIcon.svg';

function Systems() {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const selectedSystemId = useSelector(
    (state: RootState) => state.systems.selectedSystem
  );
  const { t, i18n } = useTranslation();
  const [selectedSystemIdState, setSelectedSystemIdState] =
    useState(selectedSystemId);
  const { data, error, isLoading, refetch } = useGetPageDataQuery('Systems', {
    skip: false,
  });
  const { data: systemData, refetch: refetchSystemData } =
    useGetSystemsDataQuery(selectedSystemIdState, {
      skip: !selectedSystemIdState,
    });

  useEffect(() => {
    refetch();
  }, [currentLanguage, refetch, error]);

  useEffect(() => {
    if (data) {
      setSelectedSystemIdState(data['system'].id);
    }
  }, [data, selectedSystemId]);

  useEffect(() => {
    if (selectedSystemIdState) {
      refetchSystemData();
    }
  }, [selectedSystemIdState, refetchSystemData]);

  useEffect(() => {
    if (selectedSystemId) {
      setSelectedSystemIdState(selectedSystemId);
    }
  }, [selectedSystemId]);

  useEffect(() => {
    if (selectedSystemIdState) {
      refetchSystemData();
    }
  }, [currentLanguage, selectedSystemIdState, refetchSystemData]);

  const BottomNavBarIcons = [
    HouseIcon,
    AppIcon,
    ProductIcon,
    ContactIcon,
    MoreIcon,
  ];
  const BottomNavBarTitles = [
    t('Home'),
    t('Applications'),
    t('Products'),
    t('Contact Us'),
    t('More'),
  ];

  const SystemsFilter = useMemo(() => {
    if (!data) return { sections: [] };

    const sections = data.sidebar.map(
      (category: { categoryName: any | string | string[]; system: any[] }) => ({
        name: t(category.categoryName),
        subItems: category.system.map(
          (app: { id: number; title: any | string | string[] }) => ({
            id: app.id.toString(),
            title: t(app.title),
          })
        ),
      })
    );

    return { sections };
  }, [data, t]);

  return (
    data &&
    systemData && (
      <div className="flex flex-col items-center">
        <Header Active="Systems" />
        <MobileBottomNavBar
          icons={BottomNavBarIcons}
          titles={BottomNavBarTitles}
          active={t('System')}
        />
        <NavigateTopButton />
        <ContactUsButton />
        <MainHeader title={t('Smart Systems Solutions')} page={t('Systems')} />
        <div
          className={`w-[20.964375rem] custom-height-mq:w-[77rem] flex flex-col custom-height-mq:flex-row items-start justify-center gap-[1rem] py-[1rem] custom-height-mq:gap-[5rem] custom-height-mq:py-[4rem] ${
            currentLanguage === 'ar' ? 'rtl' : 'ltr'
          }`}
        >
          <Filter
            title={t('Smart Systems')}
            items={SystemsFilter}
            selectedProductId={selectedSystemIdState}
            onFilterChange={setSelectedSystemIdState}
          />
          <div className="flex flex-col max-w-[20.964375rem] custom-height-mq:max-w-[55.6875rem] items-center justify-center gap-11">
            <div className="relative flex flex-col items-center justify-center">
              <img
                src={systemData['system']['app_page_header_image'][0]['image']}
                alt="Waterproofing"
                className="w-full h-[9.3125rem] custom-height-mq:h-[18.625rem]"
              />
              <div className="absolute bottom-[0rem] custom-height-mq:bottom-[-3rem]">
                <PagesTitle title={systemData['system']['title']} />
              </div>
            </div>
            <div className="flex items-center justify-center w-[18.4375rem] h-[10.4925rem] custom-height-mq:w-[49.3125rem] custom-height-mq:h-[28.0625rem] custom-height-mq:mt-[4rem]">
              <iframe
                width="100%"
                height="100%"
                src={systemData['system']['videos'][0]['video_url']}
                title="About Chema Foam"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <WaterproofingApplication
              title={systemData['system']['title']}
              description={systemData['system']['description']}
            />
            {systemData['system']['system_comparison'] &&
              systemData['system']['system_comparison'].length > 0 && (
                <Difference
                  comparison={systemData['system']['system_comparison']}
                />
              )}
            <FeaturesBenefits
              header={
                i18n.language === 'ar'
                  ? `${t('System Features')} ${systemData['system']['title']}`
                  : `${systemData['system']['title']} ${t('System Features')}`
              }
              feature={true}
              bgColor={'bg-[#5A5A5A] bg-opacity-10'}
              items={systemData['system']['featurs']}
              image={systemData['system']['featurs_image'][0]['image']}
            />
            <FeaturesBenefits
              header={
                i18n.language === 'ar'
                  ? `${t('Application Benefits')} ${
                      systemData['system']['title']
                    }`
                  : `${systemData['system']['title']} ${t(
                      'Application Benefits'
                    )}`
              }
              feature={false}
              bgColor={'bg-[#1EBAE5] bg-opacity-10'}
              items={systemData['system']['benefits']}
              image={systemData['system']['benefits_image'][0]['image']}
            />
            <div className="flex flex-col w-full gap-3 custom-height-mq:gap-6 items-center justify-center">
              <div className="hidden custom-height-mq:block text-2xl font-bold text-[#1C4585] text-center mb-[1rem]">
                {t('System Places')}
              </div>
              {systemData['system']['system_places'].map((item: any) => (
                <Accordion
                  key={item.name}
                  Icon={item.icon}
                  title={item.name}
                  Content={
                    <AccordionContent
                      context={item.descrition}
                      image={item.image}
                      recommendedProducts={systemData['recommended_products']}
                    />
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <MainFooter />
      </div>
    )
  );
}

export default Systems;
