import { useState, useEffect } from 'react';

interface BackgroundImageProps {
  images: string[];
  setActiveDotSection1: React.Dispatch<React.SetStateAction<number>>;
  activeDotSection1 : number;
  isPaused: boolean;
}

function BackgroundImage({ images , setActiveDotSection1 , activeDotSection1 ,isPaused }: BackgroundImageProps) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setActiveDotSection1(currentImageIndex);
    }, 2000);
     if(isPaused)
     {clearTimeout(timeout);}
    return () => clearTimeout(timeout);
  }, [currentImageIndex, images]);

  useEffect(() => {
    setCurrentImageIndex(activeDotSection1);
  }, [activeDotSection1]);

  const currentImage = images[activeDotSection1];

  return (
    <div className="min-h-screen custom-height-mq-landscape:min-h-[160vh] bg-radial-gradient relative">
      <img
        key={currentImage}
        src={currentImage}
        alt="Welcome"
        className={`object-cover w-screen h-full mix-blend-overlay absolute inset-0 transition-opacity duration-500 fade-in-active`}
      />
    </div>
  );
}

export default BackgroundImage;
