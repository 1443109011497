import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedSystem: '2',
};

const systemsSlice = createSlice({
  name: 'systems',
  initialState,
  reducers: {
    selectSystem: (state, action) => {
      state.selectedSystem = action.payload;
    },
  },
});

export const { selectSystem } = systemsSlice.actions;
export default systemsSlice.reducer;
