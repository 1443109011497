import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import SearchIcon from '../../assets/SearchIcon.svg';
import { ReactComponent as XIcon } from '../../assets/XIcon.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useTranslation } from 'react-i18next';
import { useSearchQuery } from '../../../../store/apiService';
import { selectProduct } from '../../../../store/productsSlice';
import { selectApplication } from '../../../../store/applicationsSlice';
import { selectSystem } from '../../../../store/systemsSlice';

interface SearchBarProps {
  Header?: boolean;
}

function SearchBar({ Header }: SearchBarProps) {
  const [isFocused, setIsFocused] = useState(false);
  const [searchText, setSearchText] = useState('');
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data, isLoading, error } = useSearchQuery(searchText, {
    skip: !searchText,
  });

  const [searchResults, setSearchResults] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      const results: any = [];
      if (data.applications) {
        data.applications.forEach((item: any) => {
          results.push({ ...item, type: 'application' });
        });
      }
      if (data.products) {
        data.products.forEach((item: any) => {
          results.push({ ...item, type: 'product' });
        });
      }
      if (data.systems) {
        data.systems.forEach((item: any) => {
          results.push({ ...item, type: 'system' });
        });
      }
      setSearchResults(results);
    }
  }, [data]);

  const handleSearchTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
    setIsFocused(false);
  };

  const handleResultClick = (result: any) => {
    if (result.type === 'application') {
      dispatch(selectApplication(result.id));
      navigate(`/applications`);
    } else if (result.type === 'product') {
      dispatch(selectProduct(result.id));
      navigate(`/products`);
    } else if (result.type === 'system') {
      dispatch(selectSystem(result.id));
      navigate(`/systems`);
    }
    setIsFocused(false);
    setSearchText('');
  };

  return (
    <div className={`relative flex items-center justify-center ${currentLanguage === "ar" ? "rtl" : "ltr"}`}>
      <input
        type="text"
        className={`${Header ? "w-[57vw]" : "w-[16.46875rem] custom-height-mq:w-[40vw]"} h-[2rem] custom-height-mq:h-[3.25rem] text-[0.5rem] custom-height-mq:text-base p-4 caret-[#877f7f] bg-[#D7D7D7] border-gray-300 focus:outline-none ${isFocused ? 'placeholder-transparent' : ''}`}
        placeholder={t("Find Products, Application, Systems and Solutions.....")}
        value={searchText}
        onChange={handleSearchTextChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <div className={`flex flex-row gap-2 custom-height-mq:gap-5 ${currentLanguage === "ar" ? "left-[1rem]" : "right-[1rem]"} absolute`}>
        <img src={SearchIcon} alt='Search' className='w-[0.5375rem] h-[0.5375rem] custom-height-mq:w-4 custom-height-mq:h-4 transition-all hover:scale-[1.2] hover:cursor-pointer' loading="lazy" />
        {isFocused && searchText && (
          <XIcon className='hover:cursor-pointer w-[0.5375rem] h-[0.5375rem] custom-height-mq:w-4 custom-height-mq:h-4' onClick={handleClearSearch} />
        )}
      </div>
      {isFocused && searchText && (
        <div className="absolute top-full mt-2 w-full bg-[#1F3566] text-white shadow-lg z-10 max-h-[308px] overflow-auto">
          {isLoading ? (
            <div className="p-3 pb-[13px] pl-[18px] text-gray-500">
              {t("Loading...")}
            </div>
          ) : error ? (
            <div className="p-3 pb-[13px] pl-[18px] text-gray-500">
              {t("Error fetching results")}
            </div>
          ) : searchResults.length > 0 ? (
            searchResults.map((result, index) => (
              <div key={index} className="p-3 text-xs custom-height-mq:text-base hover:bg-[#FBAD4A] hover:text-[#1F3566] pb-[13px] pl-[18px] cursor-pointer" onMouseDown={() => handleResultClick(result)}>
                {result.title}
              </div>
            ))
          ) : (
            <div className="p-3 pb-[13px] pl-[18px] text-gray-500">
              {t("No results found")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SearchBar;
