import { useState } from 'react';
import DropDownGreyIcon from "../../Pages/About Us/assets/DropdownGreyIcon.svg";

interface AccordionProps {
  Icon: string;
  title: string;
  Content: React.ReactNode;
  Product ?: boolean;
}

function Accordion({ Icon, title, Content , Product }: AccordionProps) {
  const [showContent, setShowContent] = useState(false);

  return (
    <>
      <div
        className={`w-full group ${Product ? "h-[3rem] custom-height-mq:h-[5rem]": "h-[3rem] custom-height-mq:h-[5.9375rem]"} bg-[#5A5A5A] bg-opacity-10 flex justify-center items-center px-[1.25rem] custom-height-mq:px-[3.25rem] hover:bg-[#FBAD4A] hover:cursor-pointer`}
        onClick={() => setShowContent(!showContent)}
      >
        <div className="flex flex-row items-center justify-center gap-3 custom-height-mq:gap-6">
          <img src={Icon} alt="Press to drop" className={`${Product ? "w-[1rem] h-[1rem] custom-height-mq:w-[1.5rem] custom-height-mq:h-[1.5rem]":"w-[1.831875rem] h-[1.736875rem] custom-height-mq:w-[3.626875rem] custom-height-mq:h-[3.4375rem]"}`} />
          <div className={`text-left text-xs custom-height-mq:text-xl ${Product ?"text-[#1C4585]":"text-[#5A5A5A]"} font-[500] group-hover:text-[#1C4585] tracking-normal capitalize`}>{title}</div>
        </div>
        <div className="flex-grow" />
        <img src={DropDownGreyIcon} alt="Press to drop" className={`w-[0.758125rem] h-[0.758125rem] custom-height-mq:w-[1.25rem] custom-height-mq:h-[1.25rem] ${showContent ? "rotate-180" : ""}`} />
      </div>
      {showContent && (
        <div className="w-full px-[1.5rem] custom-height-mq:px-[3.25rem]">
          {Content}
        </div>
      )}
    </>
  );
}

export default Accordion;
