interface ProjectsNumberContentProps {
    title: string;
    main: boolean;
  }
  
  function ProjectsNumberContent({ title, main }: ProjectsNumberContentProps) {
    return main ? (
      <div className="text-base custom-height-mq:text-2xl text-[#868686] font-normal w-[11.505625rem] custom-height-mq:w-[13rem] text-center">
        {title}
      </div>
    ) : (
      <div className="text-[0.65rem] leading-3 custom-height-mq:text-sm text-[#868686] font-normal w-[5.201875rem] custom-height-mq:w-[10rem] text-center">
        {title}
      </div>
    );
  }
  
  export default ProjectsNumberContent;
  