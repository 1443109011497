import { useState } from 'react';
import DropDownGreyIcon from "../../assets/DropdownGreyIcon.svg";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

interface MenuItemProps {
  Icon: string;
  title: string;
  Content: React.ReactNode;
  ContactUs?: boolean;
}

function MenuItem({ Icon, title, Content , ContactUs }: MenuItemProps) {
  const [showContent, setShowContent] = useState(false);
  const currentLanguage = useSelector((state: RootState) => state.language.language);

  return (
    <>
      <div
        className={`w-full h-[2.5rem] custom-height-mq:w-[75rem] custom-height-mq:h-[5rem] bg-[#5A5A5A] ${currentLanguage === "ar" ? "rtl" : "ltr"} bg-opacity-10 flex justify-center items-center px-[1.5rem] custom-height-mq:px-[3.25rem] hover:bg-[#FBAD4A] hover:cursor-pointer`}
        onClick={() => setShowContent(!showContent)}
      >
        <div className="flex flex-row justify-center items-center gap-2 custom-height-mq:gap-4">
          <img src={Icon} alt="Press to drop" className="w-[1.125rem] h-[0.86rem] self-center custom-height-mq:w-[2.25rem] custom-height-mq:h-[2.25rem]" />
          <div className="text-left text-[0.76rem] custom-height-mq:text-2xl text-[#1C4585] font-bold leading-9 tracking-normal">{title}</div>
        </div>
        <div className="flex-grow" />
        <img src={DropDownGreyIcon} alt="Press to drop" className={`w-[0.75rem] h-[0.75rem] custom-height-mq:w-[1.25rem] custom-height-mq:h-[1.25rem] ${showContent ? "rotate-180" : ""}`} />
      </div>
      {showContent && (
        <div className={`${ContactUs ? "w-full" : "w-[18.4375rem]"} custom-height-mq:w-[75rem] px-[1rem] custom-height-mq:px-[3.25rem]`}>
          {Content}
        </div>
      )}
    </>
  );
}

export default MenuItem;
