import { Modal } from "antd";
import WeightCard from "./WeightCard";
import DownloadButton from "./DownloadButton";
import { useTranslation } from "react-i18next";

interface InformationModalProps {
  visible: boolean;
  onCancel: () => void;
  cardImage ?: string;
  title?: string;
  subtitle?: string
  weight?: number;
  unitOfMeasure?: string;
  pdf?: Array<any>;
}

function InformationModal({ visible, onCancel , cardImage , title , subtitle , weight , unitOfMeasure , pdf }: InformationModalProps) {
  const { t } = useTranslation();
  
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={false}
      centered={true}
      className="!w-[unset]"
      styles={{
        mask: { backgroundColor: "rgba(28, 69, 133, 0.8)" },
        content: {
          borderRadius: "0px",
          maxWidth: "52.75rem",
          maxHeight: "90vh",
          overflow: "auto",
        },
      }}
    >
      <div className="relative flex flex-col justify-center items-center gap-6 p-0 custom-height-mq:p-[2rem] pb-[0.5rem] ">
        <img
          src={cardImage}
          alt={title}
          className="w-[9.5625rem] h-[6.78125rem] custom-height-mq:w-[26rem] custom-height-mq:h-auto"
        />
        <div className="text-center text-[#1C4585] text-sm custom-height-mq:text-4xl font-bold">
          {title}
        </div>
        <div className="text-center text-xs custom-height-mq:text-base font-normal text-[#5A5A5A]">
          {subtitle}
        </div>
        <div className="flex flex-col gap-3 custom-height-mq:gap-5">
        {pdf && pdf.map((pdfItem) => (
            <DownloadButton 
              key={pdfItem.id} 
              title={t(pdfItem.title)} 
              onClick={() => window.open(pdfItem.file_url, '_blank')}
            />
          ))}
        </div>
        <WeightCard weight={weight} unitOfMeasure={unitOfMeasure} />
      </div>
    </Modal>
  );
}

export default InformationModal;
