import ArrowDownIcon from '../../assets/ArrowDownIcon.svg';

interface ExtendCardButtonProps {
    clicked : boolean;
    rotate : boolean;
    onClick: ()=> void;
}

function ExtendCardButton ({clicked,rotate,onClick} : ExtendCardButtonProps){
    return (
        <div className={`bg-[#1EBAE5] bg-opacity-75 flex justify-center items-center w-[1.210625rem] h-[1.210625rem] custom-height-mq:w-[2.75375rem] custom-height-mq:h-[2.75rem] transition-all hover:transform hover:bg-[#1C4585] hover:!scale-[1.1] hover:cursor-pointer ${clicked ? (rotate ? "rotate bg-[#c0c0c0] hover:rotate-180" : "rotate-back") : "rotate-back"}`} onClick={onClick}>
            <img src={ArrowDownIcon} alt="Expand Post" className='w-[0.33125rem] h-[0.549375rem] custom-height-mq:w-fit custom-height-mq:h-fit' loading="lazy" />
        </div>
    )
}

export default ExtendCardButton;