import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface WeightInformationCardProbs {
  weight: number | undefined;
  unitOfMeasure : string | undefined;
}

function WeightInformationCard({weight , unitOfMeasure} : WeightInformationCardProbs) {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  return (
    <div className={`flex ${currentLanguage === "ar" ? "left-0 rtl" : "right-0 ltr"} absolute w-[3.69rem] h-[2.15rem] custom-height-mq:w-[4.225rem] custom-height-mq:h-[2.575rem] bottom-0 justify-center group-hover:hidden items-center bg-[#FBAD4A]`}>
      <div className="text-sm custom-height-mq:text-base font-bold leading-6 text-justify text-[#1C4585]">
        {weight}{' '}{unitOfMeasure}
      </div>
    </div>
  );
}

export default WeightInformationCard;
