interface PrizesCardPointProbs{
    Points : string;
}


function PrizesCardPoint ({Points} : PrizesCardPointProbs) {
    return(
        <div className="flex flex-row absolute top-0 justify-center text-[#5A5A5A] font-bold text-sm custom-height-mq:text-xl items-center w-[7.61875rem] h-[1.7578125rem] custom-height-mq:w-[13rem] custom-height-mq:h-[3rem] border-2 border-t-0 border-[#FBAD4A] arabicLanguage  pb-[0.5rem] rtl">{Points}</div>
    )
}

export default PrizesCardPoint;