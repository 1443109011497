import React from 'react';
import { useSelector } from 'react-redux';
import ContactUsButton from "../../Main components/ContactUsButton";
import NavigateTopButton from "../../Main components/NavigateTopButton";
import LoginImage from "../assets/LoginImage.svg";
import ChemaGroupBlueIcon from "../assets/ChemaGroupBlueIcon.svg";
import UnderlineHeader from "../../Main components/UnderlineHeader";
import AwardsHeader from "../AwardsHeader";
import SideBar from "../SideBar";
import NoOfPoint from "../assets/Points.jpg";
import NumberOfPoints from "../assets/number-of-points.svg";
import HowToAddPointsImage from "../assets/HowToAddPointsImage.svg";
import PrizesProductCard from "../Prizes/PrizesProductCard.tsx/PrizesProductCard";
import PrizesIcon from "../assets/Prizes.svg";
import CopounIcon from "../assets/CopounGiftCard.svg";
import RevisePointsIcon from "../assets/RevisePoints.svg";
import TermsAndConditionsIcon from "../assets/TermsAndConditionsIcon.svg";
import Header from "../../Main components/Header/Header";
import AwardsChemaImage from "../assets/AwardsChemaImage.svg";
import MobileBottomNavBar from "../../Main components/MobileBottomNavBar/MobileBottomNavBar";
import PagesTitle from "../../Main components/pagesTitle";
import { RootState } from '../../../store/store';

function RevisePoints() {
  const user = useSelector((state: RootState) => state.user.user);
  const awardProducts = useSelector((state: RootState) => state.user.user?.award_products || []);
  const numberOfPoints = useSelector((state: RootState) => state.user.user?.number_of_points || 0);

  const BottomNavBarIcons = [
    TermsAndConditionsIcon,
    PrizesIcon,
    CopounIcon,
    RevisePointsIcon,
  ];
  const BottomNavBarTitles = [
    "الشروط والأحكام",
    "جوائز المسابقة",
    "إدخال كارت الخدش",
    "رصيد النقاط",
  ];

  return (
    <div className="arabicLanguage relative flex flex-col min-h-screen">
      <Header Active="" awards={true} />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={"مراجعة رصيد النقاط"}
        awards={true}
      />
      <ContactUsButton Awards={true} />
      <NavigateTopButton Awards={true} />
      <AwardsHeader />
      <div className="flex flex-row h-full overflow-hidden justify-center items-center pt-0 custom-height-mq:pt-[6.25rem] custom-height-mq:items-start right-0 relative rtl">
        <SideBar Active="مراجعة رصيد النقاط" />
        <div className="flex flex-col justify-center items-center gap-6 custom-height-mq:gap-11 w-screen custom-height-mq:w-[62.125rem] py-0 mr-0 custom-height-mq:py-[5rem] custom-height-mq:mr-[14rem]">
          <img
            src={AwardsChemaImage}
            alt="Awards"
            className="w-full object-cover h-[14rem] block custom-height-mq:hidden"
          />
          <div className="relative self-center top-[-3rem] custom-height-mq:top-[-3.5rem] block custom-height-mq:hidden h-full overflow-auto">
            <PagesTitle title="رصيد النقاط" Awards={true} />
          </div>
          <div className="w-[62.125rem] h-[33.625rem] flex flex-col custom-height-mq:flex-row ltr">
            <div className="w-full h-1/2 custom-height-mq:w-1/2 custom-height-mq:h-full">
              <img
                src={HowToAddPointsImage}
                alt="How to Add Points?"
                className="w-full h-full"
              />
            </div>
            <div className="w-full relative h-1/2 custom-height-mq:w-1/2 custom-height-mq:h-full flex flex-col justify-center items-center">
              <img
                src={NoOfPoint}
                alt="Number Of Points"
                className="w-[248px] h-[269px] absolute custom-height-mq:w-full custom-height-mq:h-full"
              />
              <div className="absolute flex flex-col gap-6 justify-center items-center">
                <img src={NumberOfPoints} alt="Points Number" className="w-[76.5px] h-[106.5px] custom-height-mq:w-full custom-height-mq:h-full" />
                <div className="flex justify-center items-center border-[1px] px-5 custom-height-mq:px-10 py-1 custom-height-mq:py-2 border-[#FBAD4A] text-[#FBAD4A] rounded-[12.5px] pb-[10px] custom-height-mq:pb-[15px] text-base custom-height-mq:text-3xl font-[500] rtl">{numberOfPoints} نقطه</div>
              </div>
            </div>
          </div>
          {awardProducts.length > 0 && (
            <>
              <UnderlineHeader
                title="الجوائز المتاحة حسب مجموع النقاط الحالي"
                Awards={true}
              />
              <div className="w-[18.4375rem] custom-height-mq:w-[55.6875rem] grid grid-cols-2 custom-height-mq:grid-cols-3 gap-3 custom-height-mq:gap-11">
                {awardProducts.map((product : any , index : any) => (
                  <PrizesProductCard
                    key={index}
                    title={product.title}
                    points={product.points}
                    image={product.image}
                  />
                ))}
              </div>
            </>
          )}
          <img
            src={LoginImage}
            alt="Login"
            className="h-[7.5rem] w-[19.9375rem] object-cover self-center mt-0 custom-height-mq:w-full custom-height-mq:h-[13.5rem] custom-height-mq:mt-[3rem]"
          />
          <img
            src={ChemaGroupBlueIcon}
            alt="Chema Group"
            className="w-[9.98625rem] h-[3.25rem] self-center my-[2rem] mb-[6rem] custom-height-mq:mb-0 custom-height-mq:mt-[7rem]"
          />
        </div>
      </div>
    </div>
  );
}

export default RevisePoints;
