import { Key, useEffect, useState } from "react";
import ContactUsButton from "../Main components/ContactUsButton";
import Header from "../Main components/Header/Header";
import MainFooter from "../Main components/MainFooter/MainFooter";
import MainHeader from "../Main components/MainHeader/MainHeader";
import NavigateTopButton from "../Main components/NavigateTopButton";
import VideosCard from "./components/VideosCard";
import VideosHeader from "./components/VideosHeader";
import ViewMoreButton from "../Home/components/FrequentlyAskedQuestionsCard/ViewMoreButton";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import ExpandingDots from "../Home/components/ExpandingDots";
import MobileBottomNavBar from "../Main components/MobileBottomNavBar/MobileBottomNavBar";
import HouseIcon from "../Main components/MobileBottomNavBar/assets/HomeIcon.svg";
import AppIcon from "../Main components/MobileBottomNavBar/assets/AppsIcon.svg";
import ProductIcon from "../Main components/MobileBottomNavBar/assets/ProductIcon.svg";
import ContactIcon from "../Main components/MobileBottomNavBar/assets/ContactIcon.svg";
import MoreIcon from "../Main components/MobileBottomNavBar/assets/MoreIcon.svg";
import { useGetPageDataQuery } from "../../store/apiService";


function Videos() {
  const [showAllApplications, setShowAllApplications] = useState(false);
  const [showAllSystems, setShowAllSystems] = useState(false);
  const [showAllVideos, setShowAllVideos] = useState(false);
  const [activeDotSectionApplications, setActiveDotSectionApplications] = useState(0);
  const [activeDotSectionSystems, setActiveDotSectionSystems] = useState(0);
  const [activeDotSectionVideos, setActiveDotSectionVideos] = useState(0);
  const { data, error, isLoading, refetch } = useGetPageDataQuery("Videos", {
    skip: false, // Ensure that the query does not skip fetching data
  });
  const handleDotChange = (index: number, section: number) => {
    switch (section) {
      case 1:
        setActiveDotSectionApplications(index);
        break;
      case 2:
        setActiveDotSectionSystems(index);
        break;
      case 3:
        setActiveDotSectionVideos(index);
        break;
      default:
        break;
    }
    // Add any additional logic you want to perform on dot change
  };
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  const { t } = useTranslation();
  useEffect(() => {
    refetch();
  }, [currentLanguage, refetch , error]);
  const BottomNavBarIcons = [
    HouseIcon,
    AppIcon,
    ProductIcon,
    ContactIcon,
    MoreIcon,
  ];
  const BottomNavBarTitles = [
    t("Home"),
    t("Applications"),
    t("Products"),
    t("Contact Us"),
    t("More"),
  ];

  return data && (
    <div className="flex flex-col">
      <Header Active="Videos" />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={t("Videos")}
      />
      <NavigateTopButton />
      <ContactUsButton />
      <MainHeader title={t("Chema Foam Videos")} page={t("Videos")} />
      <div
        className={`w-screen flex flex-col justify-center items-center ${
          currentLanguage === "ar" ? "rtl" : "ltr"
        }`}
      >
        <div className="w-screen min-h-[20rem] custom-height-mq:min-h-[42.25rem] flex flex-col py-[1rem] custom-height-mq:py-[5rem] items-center justify-center">
          <div className="w-[20.964375rem] custom-height-mq:w-[75rem] min-h-[25.375rem] items-center custom-height-mq:items-stretch custom-height-mq:items-normal justify-center custom-height-mq:justify-between gap-5 custom-height-mq:gap-9 flex flex-col">
            <VideosHeader first={t("Products")} rest={t("Applications")} />
            <div className="flex-row justify-between flex custom-height-mq:hidden">
            {data['products_videos'].slice(activeDotSectionApplications,activeDotSectionApplications+1).map((video: { id: Key | null | undefined; title: string; subtitle: string; videos: Array<any>; }) => (
                <VideosCard key={video.id} header={video.title} body={video.subtitle} videos={video.videos} />
                      ))}
            </div>
            <div className="flex-row justify-start gap-8 hidden custom-height-mq:flex">
            {data['products_videos'].slice(0, 3).map((video: { id: Key | null | undefined; title: string; subtitle: string; videos: Array<any>; }) => (
                <VideosCard key={video.id} header={video.title} body={video.subtitle} videos={video.videos} />
                      ))}
            </div>
            <div className="flex flex-row gap-y-8 gap-8 flex-wrap justify-center custom-height-mq:justify-start">
              {showAllApplications && (
                data['products_videos'].slice(3).map((video: { id: Key | null | undefined; title: string; subtitle: string; videos: Array<any>; }) => (
                  <VideosCard key={video.id} header={video.title} body={video.subtitle} videos={video.videos} />
                        ))
              )}
            </div>
            {data['products_videos'].length > 3 && 
            <div className="self-center hidden custom-height-mq:block">
              <ViewMoreButton
                showAllQuestions={showAllApplications}
                onClick={() => setShowAllApplications(!showAllApplications)}
              />
            </div>
            }
            <div className="block custom-height-mq:hidden">
            <ExpandingDots
              totalDots={data['products_videos'].length}
              activeDot={activeDotSectionApplications}
              onChange={(index) => handleDotChange(index, 1)}
            />
            </div>
          </div>
        </div>
        <div className="w-screen min-h-[20rem] custom-height-mq:min-h-[42.25rem] flex flex-col py-[1rem] custom-height-mq:py-[5rem] items-center justify-center bg-[#FBAD4A] bg-opacity-10">
        <div className="w-[20.964375rem] custom-height-mq:w-[75rem] min-h-[25.375rem] items-center custom-height-mq:items-stretch custom-height-mq:items-normal justify-center custom-height-mq:justify-between gap-5 custom-height-mq:gap-9 flex flex-col">
            <VideosHeader first={t("Smart")} rest={t("Solution System")} />
            <div className="flex-row justify-between flex custom-height-mq:hidden">
            {data['systems_videos'].slice(activeDotSectionSystems, activeDotSectionSystems+1).map((video: { id: Key | null | undefined; title: string; subtitle: string; videos: Array<any>; }) => (
                <VideosCard key={video.id} header={video.title} body={video.subtitle} videos={video.videos} />
                      ))}
            </div>
            <div className="flex-row justify-start gap-8 hidden custom-height-mq:flex">
            {data['systems_videos'].slice(0, 3).map((video: { id: Key | null | undefined; title: string; subtitle: string; videos: Array<any>; }) => (
                <VideosCard key={video.id} header={video.title} body={video.subtitle} videos={video.videos} />
                      ))}
            </div>
            <div className="flex flex-row gap-y-8 gap-8 flex-wrap justify-center custom-height-mq:justify-start">
              {showAllSystems && (
                data['systems_videos'].slice(3).map((video: { id: Key | null | undefined; title: string; subtitle: string; videos: Array<any>; }) => (
                  <VideosCard key={video.id} header={video.title} body={video.subtitle} videos={video.videos} />
                        ))
              )}
            </div>
            {data['systems_videos'].length > 3 && 
            <div className="self-center hidden custom-height-mq:block">
              <ViewMoreButton
                showAllQuestions={showAllSystems}
                onClick={() => setShowAllSystems(!showAllSystems)}
              />
            </div> }
            <div className="block custom-height-mq:hidden">
            <ExpandingDots
              totalDots={data['systems_videos'].length}
              activeDot={activeDotSectionSystems}
              onChange={(index) => handleDotChange(index, 2)}
            />
            </div>
          </div>
        </div>
        <div className="w-screen min-h-[20rem] custom-height-mq:min-h-[42.25rem] py-[1rem] custom-height-mq:py-[5rem] flex flex-col items-center justify-center bg-[#1EBAE5] bg-opacity-10">
        <div className="w-[20.964375rem] custom-height-mq:w-[75rem] min-h-[25.375rem] items-center custom-height-mq:items-stretch custom-height-mq:items-normal justify-center custom-height-mq:justify-between gap-5 custom-height-mq:gap-9 flex flex-col">
            <VideosHeader first={t("Other")} rest={t("Videos")} />
            <div className="flex-row justify-between flex custom-height-mq:hidden">
            {data['other_videos'].slice(activeDotSectionVideos, activeDotSectionVideos+1).map((video: { id: Key | null | undefined; title: string; subtitle: string; videos: Array<any>; }) => (
                <VideosCard key={video.id} header={video.title} body={video.subtitle} videos={video.videos} />
                      ))}
            </div>
            <div className="flex-row justify-start gap-8 hidden custom-height-mq:flex">
            {data['other_videos'].slice(0, 3).map((video: { id: Key | null | undefined; title: string; subtitle: string; videos: Array<any>; }) => (
                <VideosCard key={video.id} header={video.title} body={video.subtitle} videos={video.videos} />
                      ))}
            </div>
            <div className="flex flex-row gap-y-8 gap-8 flex-wrap justify-center custom-height-mq:justify-start">
              {showAllVideos && (
                data['other_videos'].slice(3).map((video: { id: Key | null | undefined; title: string; subtitle: string; videos: Array<any>; }) => (
                  <VideosCard key={video.id} header={video.title} body={video.subtitle} videos={video.videos} />
                        ))
              )}
            </div>
            {data['other_videos'].length > 3 && 
            <div className="self-center hidden custom-height-mq:block">
              <ViewMoreButton
                showAllQuestions={showAllVideos}
                onClick={() => setShowAllVideos(!showAllVideos)}
              />
            </div>}
            <div className="block custom-height-mq:hidden">
            <ExpandingDots
              totalDots={data['other_videos'].length}
              activeDot={activeDotSectionVideos}
              onChange={(index) => handleDotChange(index, 3)}
            />
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </div>
  );
}

export default Videos;
