import { Modal } from "antd";
import SketchDrawingImage from "../../Home/assets/SketchDrawing.svg";
import WeightCard from "./WeightCard";
import InputLabel from "./InputLabel";
import CalcModalButton from "./CalcModalButton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface InformationModalProps {
  visible: boolean;
  onCancel: () => void;
  title?: string;
  subtitle?: string;
  cardImage ?: string;
  coverageRate ?: number;
  coverageRateUnit ?: string;
  weight?: number;
  unitOfMeasure?: string;
}

function InformationModal({ visible, onCancel , title , subtitle , cardImage , coverageRate , coverageRateUnit , weight , unitOfMeasure }: InformationModalProps) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  const { t } = useTranslation();
  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={false}
      centered={true}
      className="!w-[unset]"
      styles={{
        mask: { backgroundColor: "rgba(28, 69, 133, 0.8)" },
        content: {
          borderRadius: "0px",
          maxWidth: "52.75rem",
          maxHeight: "90vh",
          overflow: "auto",
        },
      }}
    >
      <div className="relative flex flex-col justify-center items-center gap-6 p-0 custom-height-mq:p-[2rem] pb-[0.5rem] ">
        <img
          src={cardImage}
          alt={title}
          className="w-[9.5625rem] h-[6.78125rem] custom-height-mq:w-[26rem] custom-height-mq:h-auto"
        />
        <div className="text-center text-[#1C4585] text-sm custom-height-mq:text-4xl font-bold">
          {title}
        </div>
        <div className="text-center text-xs custom-height-mq:text-base font-normal text-[#5A5A5A]">
          {subtitle}
        </div>
        <div className="flex flex-row justify-center items-center w-[95%]">
          <div className="text-center text-xs custom-height-mq:text-base font-normal text-[#5A5A5A] w-[21.875rem] h-[4.125rem]">
            {t("Enter the dimensions of the space of the place you are isolating against water leaks (bathroom - kitchen - walls......).")}
          </div>
          <div className="flex-grow" />
          <img
            src={SketchDrawingImage}
            alt="Sketch Drawing"
            className="w-[7.25rem] h-[2.78125rem] self-baseline custom-height-mq:self-center custom-height-mq:h-[5.75rem] custom-height-mq:w-[14.5rem]"
          />
        </div>
        <div className={`flex flex-col custom-height-mq-landscape:flex-row custom-height-mq-landscape:w-fit gap-2 items-center custom-height-mq:flex-row w-full justify-around ${currentLanguage === "ar" ? "rtl" : "ltr"}`}>
            <div className="flex flex-col gap-2">
                <InputLabel label={t("Unit Used")} />
                <select className="rtl h-[2.3rem] w-[4rem] p-1 custom-height-mq:w-[8.375rem] custom-height-mq:h-[4rem] custom-height-mq:p-4 text-[#1C4585] border-2 text-center text-xs custom-height-mq:text-lg font-normal leading-2">
                <option value="Meter">{coverageRateUnit}</option>
                </select>
            </div>
            <div className="flex flex-row justify-between custom-height-mq-landscape:justify-start custom-height-mq-landscape:gap-3 custom-height-mq:justify-around w-full">
            <div className="flex flex-col gap-2">
                <InputLabel label={t("Length*")} />
                <input placeholder={t("Length in metres")} className="w-[7.96875rem] h-[2rem] custom-height-mq:w-[15.9375rem] custom-height-mq:h-[4rem] p-4 text-[#1C4585] border-2 text-xs custom-height-mq:text-lg font-normal">
                </input>
            </div>
            <div className="flex flex-col gap-2">
                <InputLabel label={t("Width*")} />
                <input placeholder={t("Width in metres")} className="w-[7.96875rem] h-[2rem] custom-height-mq:w-[15.9375rem] custom-height-mq:h-[4rem] p-4 text-[#1C4585] border-2 text-xs custom-height-mq:text-lg font-normal">
                </input>
            </div>
            </div>
        </div>
        <div className="flex flex-row gap-5">
            <CalcModalButton title={t("Reset")} bgColor="bg-[#D7D7D7]" TextColor="text-[#868686]" hoverBgColor="bg-[#1C4585]" hoverTextColor="text-white"  />
            <CalcModalButton title={t("Submit")} bgColor="bg-[#FBAD4A]" TextColor="text-[#1C4585]" hoverBgColor="bg-[#1C4585]" hoverTextColor="text-white"  />
        </div>
        <WeightCard weight={weight} unitOfMeasure={unitOfMeasure} />
      </div>
    </Modal>
  );
}

export default InformationModal;
