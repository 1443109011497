interface SystemsCardContentProps {
    title: string;
  }
  
  function SystemsCardContent({ title }: SystemsCardContentProps) {
    return (
      <div className="text-[#D7D7D7] text-[0.65rem] leading-[1rem] custom-height-mq:text-sm font-normal capitalize tracking-normal text-center custom-height-mq:text-justify">
        {title}
      </div>
    );
  }
  
  export default SystemsCardContent;
  