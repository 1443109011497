interface CardDataHeaderProps {
    title: string;
  }


function CardDataHeader({title}:CardDataHeaderProps){
    return(
        <div className="text-white text-center font-bold text-lg line-clamp-[3]">
            {title}
        </div>
    )
}

export default CardDataHeader;