interface AwardsContentTextProps{
    text : string;
}

function AwardsContentText({text} : AwardsContentTextProps) {
    return (
        <div className="text-[#5A5A5A] text-base font-normal arabicLanguage">{text}</div>
    )
}

export default AwardsContentText;