import { useTranslation } from 'react-i18next';

interface WaterproofingApplicationProbs {
  title: string;
  description: string;
}

function WaterproofingApplication({
  title,
  description,
}: WaterproofingApplicationProbs) {
  const { t, i18n } = useTranslation();

  return (
    <div className="h-auto w-full hidden custom-height-mq:flex flex-col justify-center items-center gap-5 p-[3rem] bg-[#1EBAE5] bg-opacity-10">
      <div className="text-2xl font-bold text-center text-[#1C4585]">
        {i18n.language === 'ar'
          ? `${t('Application')} ${title}`
          : `${title} ${t('Application')}`}
      </div>
      <div className="text-sm font-normal text-[#5A5A5A] text-justify capitalize">
        {description}
      </div>
    </div>
  );
}

export default WaterproofingApplication;
