import { Divider } from 'antd';
import PhoneCallIcon from '../assets/CallUsGreyIcon.svg';
import React from 'react';

interface DistCardProps {
    icon: string;
    title: string;
    details : Array<any>;
}

function DistCard({ icon, title , details }: DistCardProps) {
    return (
        <div className="w-[10.2125rem] h-[15.45rem] custom-height-mq:w-[17.06rem] custom-height-mq:h-[19.38rem] py-[1.5rem] bg-[#FBAD4A] bg-opacity-10 flex flex-col justify-start">
            <div className="flex flex-row justify-start px-[1.5rem] custom-height-mq:px-[2rem] items-center gap-3">
                <img src={icon} alt={title} className="w-[1.3rem] h-[1.3rem] custom-height-mq:w-[2.25rem] custom-height-mq:h-[2.25rem]" />
                <div className="text-base custom-height-mq:text-2xl font-bold text-[#1C4585]">{title}</div>
            </div>
            <Divider style={{ borderColor: '#FBAD4A' ,  borderWidth: '1.5px' }} />
            <div className='px-[1.5rem] custom-height-mq:px-[2rem] gap-3 flex flex-col justify-center items-start'>
                {details.map((detail: any) => (<React.Fragment key={detail.id}><div className='font-bold text-base custom-height-mq:text-xl text-[#5A5A5A]'>{detail.city}</div>
                <div className='flex flex-row gap-2 justify-start custom-height-mq:justify-center items-center'>
                    <img src={PhoneCallIcon} alt="Phone No." className='w-[1rem] h-[1rem] custom-height-mq:w-[1.75rem] custom-height-mq:h-[1.75rem]' />
                    <div className='font-normal text-xs custom-height-mq:text-2xl text-[#868686]'>{detail.phone}</div>
                </div></React.Fragment>))}
            </div>
        </div>
    );
}

export default DistCard;
