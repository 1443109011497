import React from 'react';
import CardContext from './CardContext';
import CardDataContent from './CardDataContent';
import CardDataHeader from './CardDataHeader';
import NonExpandingButton from './NonExpandingButton';
import { useTranslation } from 'react-i18next';

interface ApplicationsCardProbs {
  image: string;
  title : string;
  description : string;
  id: number;
}

function ApplicationsCard({image, title , description , id} : ApplicationsCardProbs) {
  const { t } = useTranslation();
  return (
    <div className="group relative justify-between items-center flex flex-col w-[13.625rem] custom-height-mq:w-[17.0625rem] h-[21.125rem] hover:bg-[#1C4585] hover:border-[#1C4585] hover:ContextCardShadow hover:border-opacity-10 hover:opacity-80 border-2 duration-1000 transform border-solid border-[#FBAD4A]">
      <img
        src={image}
        alt="Chemo Workers"
        className="w-full h-[80%] duration-500 transform group-hover:h-[7058%] object-cover mix-blend-overlay"
        loading="lazy"
      />
      <CardContext title={title} />
      <div className='absolute p-5 gap-5 w-full h-full flex flex-col items-center justify-center opacity-0 transition-opacity duration-500 ease-in-out group-hover:opacity-100'>
        <div className='flex flex-col items-center justify-center'>
          <CardDataHeader title={title} />
          <CardDataContent title={description} />
        </div>
        <div>
          <NonExpandingButton title={t("More Details")} bgColor='bg-[#D7D7D7]' hoverBgColor='bg-[#FBAD4A]' textColor='text-[#1C4585]' hoverTextColor='text-[#1C4585]' IconType='None' id={id} />
        </div>
      </div>
    </div>
  );
}

export default React.memo(ApplicationsCard);
