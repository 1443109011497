import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface PagesTitleProps {
    title: string;
    WhoWeAre?: boolean;
    Awards?: boolean;
  }
  
  function PagesTitle({ title ,WhoWeAre , Awards }: PagesTitleProps) {
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    const capitalizedTitle = title.toUpperCase();
  
    return (
      <div
        className={`h-[2.8125rem] custom-height-mq:h-[6.75rem] flex items-center ${Awards ? "arabicLanguage pb-[0.5rem]" : ""} ${WhoWeAre? (currentLanguage === "ar"? "w-[12.8125rem] custom-height-mq:w-[36.375rem]":"tracking-[0.1rem] custom-height-mq:tracking-[0.3rem] w-[12.8125rem] custom-height-mq:w-[36.375rem]"): "tracking-[0.1rem] w-fit"} custom-height-mq:leading-[3.3rem] justify-center text-base custom-height-mq:text-4xl font-normal custom-height-mq:font-semibold text-center px-[2rem] custom-height-mq:px-[4.25rem] text-white bg-[#1F3566] bg-opacity-80`}
      >
        {capitalizedTitle}
      </div>
    );
  }
  
  export default PagesTitle;
  