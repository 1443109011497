import ProjectsContent from "./ProjectsCardContent";
import ProjectsNumberCard from "./ProjectsNumberCard";
import { useTranslation } from "react-i18next";

interface ProjectsCardProbs {
  items: Array<any>;
}

function ProjectsCard({items} : ProjectsCardProbs) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-[4rem] w-[20rem] custom-height-mq:w-[61.875rem]">
      <ProjectsContent title={items[0]['description']} />
      <div className="flex flex-col custom-height-mq:flex-row gap-9 justify-center items-center">
        <div className="flex flex-row gap-9 justify-center items-center">
        <ProjectsNumberCard
          icon={items[0]['companies_icon']}
          main={false}
          number={items[0]['companies_number']}
          title={t("Total Chema Foam Projects")}
        />
        <ProjectsNumberCard
          icon={items[0]['house_icon']}
          main={false}
          number={items[0]['house_number']}
          title={t("Total Chema Foam Projects")}
        />
        </div>
        <ProjectsNumberCard
          icon={items[0]['total_icon']}
          main={true}
          number={items[0]['total_projects']}
          title={t("Total Chema Foam Projects")}
        />
        <div className="flex flex-row gap-9 justify-center items-center">
        <ProjectsNumberCard
          icon={items[0]['national_icon']}
          main={false}
          number={items[0]['national_number']}
          title={t("Total Chema Foam Projects")}
        />
        <ProjectsNumberCard
          icon={items[0]['factories_icon']}
          main={false}
          number={items[0]['factories_number']}
          title={t("Total Chema Foam Projects")}
        />
        </div>
      </div>
    </div>
  );
}

export default ProjectsCard;
