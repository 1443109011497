import ContactUsButton from "../Main components/ContactUsButton";
import Header from "../Main components/Header/Header";
import MainFooter from "../Main components/MainFooter/MainFooter";
import MainHeader from "../Main components/MainHeader/MainHeader";
import NavigateTopButton from "../Main components/NavigateTopButton";
import PagesTitle from "../Main components/pagesTitle";
import WhoWeAreContent from "./components/WhoWeAreContent";
import WhoWeAreHeader from "./components/WhoWeAreHeader";
import MenuItem from "./components/MenuItem/MenuItem";
import BriefCaseIcon from "./assets/BriefcaseIcon.svg";
import MessageIcon from "./assets/MessageIcon.svg";
import GoalsIcon from "./assets/GoalsIcon.svg";
import UnderlineHeader from "../Main components/UnderlineHeader";
import ScrollableBox from "../Home/components/ProjectsCard/ScrollableBox";
import ExpandingDots from "../Home/components/ExpandingDots";
import ProjectsCard from "../Home/components/ProjectsCard/ProjectsCard";
import GoalsContent from "../About Us/components/MenuItem/GoalsContent";
import MessageContent from "../About Us/components/MenuItem/MessageContent";
import StoryContent from "../About Us/components/MenuItem/StoryContent";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import MobileBottomNavBar from "../Main components/MobileBottomNavBar/MobileBottomNavBar";
import HouseIcon from "../Main components/MobileBottomNavBar/assets/HomeIcon.svg";
import AppIcon from "../Main components/MobileBottomNavBar/assets/AppsIcon.svg";
import ProductIcon from "../Main components/MobileBottomNavBar/assets/ProductIcon.svg";
import ContactIcon from "../Main components/MobileBottomNavBar/assets/ContactIcon.svg";
import MoreIcon from "../Main components/MobileBottomNavBar/assets/MoreIcon.svg";
import { useGetPageDataQuery } from '../../store/apiService';

    
function About() {
    const [activeDotClientSection, setActiveDotClientSection] = useState(0);
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    const { t } = useTranslation();
    const { data, error, isLoading, refetch } = useGetPageDataQuery("AboutUs", {
      skip: false, // Ensure that the query does not skip fetching data
    });
  
    useEffect(() => {
      refetch();
    }, [currentLanguage, refetch , error]);

    const handleDotChange = (index: number) => {
      setActiveDotClientSection(index);
    }


  // const handleScrollChange = (scrollPercent : any) => {
  //   const totalDots = 4;
  //   const dotIndex = Math.min(totalDots - 1, Math.floor(scrollPercent * totalDots));
  //   setActiveDotClientSection(dotIndex);
  // };
    const BottomNavBarIcons = [
      HouseIcon,
      AppIcon,
      ProductIcon,
      ContactIcon,
      MoreIcon,
    ];
    const BottomNavBarTitles = [
      t("Home"),
      t("Applications"),
      t("Products"),
      t("Contact Us"),
      t("More"),
    ];

  return data && (
    <div className="flex flex-col">
      <Header Active="About Us" />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={t("About Us")}
      />
      <NavigateTopButton />
      <ContactUsButton />
      <MainHeader title={t("Chema Foam Profile")} page={t("About Us")} />
      <div className="w-screen flex flex-col justify-center items-center gap-[3.5rem]">
        <div className="h-[3.25rem] relative flex items-center justify-center">
          <div className="absolute top-[-1.5rem]  custom-height-mq:top-[-3.5rem]">
            <PagesTitle title={t("Who We Are ?!")} WhoWeAre={true} />
          </div>
        </div>
        <div className="flex flex-col gap-2 custom-height-mq:gap-5 items-center justify-center bg-[#1EBAE5] bg-opacity-10 h-[19.75rem] w-screen">
          <WhoWeAreHeader title={data['whoWe'][0]['title']} />
          <WhoWeAreContent title={data['whoWe'][0]['description']} />
        </div>
        <div className="flex items-center justify-center bg-opacity-20 w-[18.445625rem] h-[10.48625rem] custom-height-mq:w-[49.25rem] custom-height-mq:h-[28rem]">
        <iframe
        width="100%"
        height="100%"
        src={data['whoWe'][0]['videos'][0]['video_url']}
        title="About Chema Foam"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
    />
        </div>
        <div className={`flex flex-col w-[20.9375rem] custom-height-mq:w-full justify-center items-center gap-4 ${currentLanguage === "ar" ? "rtl" : "ltr"} `}>
            <MenuItem title={t("Chema Foam Story")} Content={<StoryContent items={data["story"]} />} Icon={BriefCaseIcon} />
            <MenuItem title={t("Our Message")} Content={<MessageContent items={data["message"]} />} Icon={MessageIcon} />
            <MenuItem title={t("Our Strategic Goals")} Content={<GoalsContent items={data["goals"]} />} Icon={GoalsIcon} />
        </div>
        <div className="w-screen bg-[#1EBAE5] bg-opacity-10 p-[5rem] gap-[5rem] h-[65.1875rem] p-[5rem 7.5rem 5rem 7.5rem] flex flex-col items-center justify-center">
        <div className="flex flex-col justify-center items-center gap-[4rem]">
          <UnderlineHeader title={data['projects'][0]['section_title']} />
          <ProjectsCard items={data['projects']} />
        </div>
        <div className="flex flex-col justify-center items-center gap-[4rem]">
          <UnderlineHeader title={t("Our Clients")} />
          <ScrollableBox activeDot={activeDotClientSection} clients={data['clients']} />
          <ExpandingDots
            totalDots={4}
            activeDot={activeDotClientSection}
            onChange={(index) => handleDotChange(index)}
          />
        </div>
      </div>
      </div>
      <MainFooter />
    </div>
  );
}

export default About;
