import WeightInformationCard from '../../Home/components/ProductsCard/WeightInformationCard';
import DownloadFileButton from './DownloadFileButton';


interface ProductDocumentsCardProps{
    title : string;
    subtitle?: string;
    image : string;
    weight?: number | undefined;
    unitOfMeasure?: string | undefined;
    Product?: boolean;
    pdf?: Array<any>;
}


function ProductDocumentsCard ({title , subtitle, Product , image ,weight, unitOfMeasure , pdf } : ProductDocumentsCardProps) {
    return(
        <div className="relative w-[17.0625rem] h-[23.375rem] border-2 border-solid border-[#FBAD4A] flex flex-col items-center justify-start gap-3">
            <img src={image} alt='Product' className='h-[15.125rem] w-fit' />
            <div className="text-[#1C4585] text-xl font-bold leading-[1.875rem] tracking-normal text-center">{title}</div>
            {!Product ?  <DownloadFileButton subtitle={subtitle} image={image} title={title} weight={weight} unitOfMeasure={unitOfMeasure} pdf={pdf} /> : <WeightInformationCard weight={weight} unitOfMeasure={unitOfMeasure} />}
        </div>

    )
}

export default ProductDocumentsCard;