import AOS from 'aos';
import { useEffect } from 'react';

interface TypographyProps {
  text: string;
  activeDotSection1: number;
}

function TypographyHeader({ text , activeDotSection1 }: TypographyProps) {
  const capitalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const words = text.split(' ');
  const firstWord = capitalizeFirstLetter(words.shift() || '');
  const restOfPhrase = words.map(capitalizeFirstLetter).join(' ');

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div key={activeDotSection1} data-aos="fade-down-right" data-aos-duration="500" className="flex flex-row gap-[0.3rem]">
      <span className="font-bold text-2xl custom-height-mq:text-6xl">{firstWord}</span>
      <span className="font-normal text-2xl custom-height-mq:text-6xl lg:ml-2 xl:ml-3">{restOfPhrase}</span>
    </div>
  );
}

export default TypographyHeader;
