import { useState } from "react";
import BlueArrowDown from "../../assets/BlueArrowDown.svg";
import GreyArrowUp from "../../assets/GreyArrowUp.svg";
import { ReactComponent as SemiBlueArrowUp } from "../../assets/SemiBlueArrowUp.svg";
import { ReactComponent as SemiblueArrowDown } from "../../assets/SemiblueArrowDown.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface ViewMoreButtonProps {
  showAllQuestions: boolean;
  onClick: () => void;
}

function ViewMoreButton({ onClick, showAllQuestions }: ViewMoreButtonProps) {
  const [isHovered, setIsHovered] = useState(false);
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-row gap-2 w-fit h-fit group hover:cursor-pointer justify-center items-center"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!showAllQuestions ? (
        <div className="text-[#1C4585] group-hover:text-[#1EBAE5] text-left text-xs custom-height-mq:text-lg font-bold leading-5">
          {t("View More")}
        </div>
      ) : (
        <div className="text-[#868686] group-hover:text-[#1EBAE5] text-left text-xs custom-height-mq:text-lg font-bold leading-5">
          {t("View Less")}
        </div>
      )}
      {isHovered ? ( showAllQuestions ?  <SemiBlueArrowUp className={`w-[0.7rem] h-[0.7rem] custom-height-mq:w-[0.935rem] custom-height-mq:h-[0.545rem] ${currentLanguage === "ar" ? "mt-[0.5rem]":""} `} /> :
        <SemiblueArrowDown className={`w-[0.7rem] h-[0.7rem] custom-height-mq:w-[0.935rem] custom-height-mq:h-[0.545rem] ${currentLanguage === "ar" ? "mt-[0.5rem]":""} `} />
      ) : (
        <img
          src={!showAllQuestions ? BlueArrowDown : GreyArrowUp}
          alt="View More"
          className={`w-[0.7rem] h-[0.7rem] custom-height-mq:w-[0.935rem] custom-height-mq:h-[0.545rem]  ${currentLanguage === "ar" ? "mt-[0.5rem]":""} `}
        />
      )}
    </div>
  );
}

export default ViewMoreButton;
