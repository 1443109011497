import { Divider } from 'antd';

interface InquiriesCardProps {
    icon: string;
    title: string;
    content: React.ReactNode;
}

function InquiriesCard({ icon, title, content }: InquiriesCardProps) {
    return (
        <div className="w-full custom-height-mq:w-[23.5rem] h-[16rem] custom-height-mq:h-[18.56rem] py-[1.5rem] bg-[#1EBAE5] bg-opacity-10 flex flex-col justify-start">
            <div className="flex flex-row justify-start px-[1.5rem] custom-height-mq:px-[2rem] items-center gap-3">
                <img src={icon} alt={title} className="w-[1.3rem] h-[1.3rem] custom-height-mq:w-[2.25rem] custom-height-mq:h-[2.25rem]" />
                <div className="text-base custom-height-mq:text-2xl font-bold text-[#1C4585]">{title}</div>
            </div>
            <Divider style={{ borderColor: '#1EBAE5' ,  borderWidth: '1.5px' }} />
            {content}
        </div>
    );
}

export default InquiriesCard;
