import PagesHeader from "../../../Main components/MainFooter/PagesHeader";
import WhiteArrowTopIcon from "../../../Home/assets/ArrowTopWhite.svg";
import { useTranslation } from "react-i18next";

interface VisitUsContentProbs {
  factoryAddress : string;
  officeAddress : string;
}

function VisitUsContent({factoryAddress,officeAddress} : VisitUsContentProbs) {
  const { t } = useTranslation();
  
    return(
        <div className="flex flex-col gap-3 px-[1.5rem] custom-height-mq:px-[2.5rem] text-[#5A5A5A]">
            <div className="font-bold text-sm custom-height-mq:text-base">
              {t("FACTORY")}
            </div>
            <PagesHeader title={factoryAddress} href="https://www.google.com/maps/place/Chema+Foam+Group+-+%D9%83%D9%8A%D9%85%D8%A7+%D9%81%D9%88%D9%85%E2%80%AD/@30.3196073,31.7795342,15z/data=!4m6!3m5!1s0x14f80796d8433167:0xb2ed4d649c3b3c9e!8m2!3d30.3196073!4d31.7795342!16s%2Fg%2F11c1rfd9c4?sa=X&ved=2ahUKEwjb24HwlZD_AhXLTaQEHXS6DfMQ_BJ6BAhMEAg&entry=tts&shorturl=1" icon={WhiteArrowTopIcon} ContactUs={true} />
            <div className="font-bold text-sm custom-height-mq:text-base">
              {t("OFFICE")}
            </div>
            <PagesHeader title={officeAddress} href="https://www.google.com/maps/place/TAQA+Misr/@30.078028,31.3113224,21z/data=!4m7!3m6!1s0x14581872016c19c1:0x599141d37b8d1d8d!8m2!3d30.0779342!4d31.3115634!15sCj0xMSBPYm91ciBCdWlsZGluZ3MgLSBTYWxhaCBTYWxlbSAtIE5hc3IgQ2l0eSBDYWlybyAtIEVneXB04oCtkgEaaGVhdGluZ19lcXVpcG1lbnRfc3VwcGxpZXLgAQA!16s%2Fg%2F1q5bpk6s2?entry=tts&shorturl=1" icon={WhiteArrowTopIcon} ContactUs={true} />
          </div>
    )
}

export default VisitUsContent;