import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface QuestionsContentProbs {
  answer: string;
}

function QuestionsContent({answer} : QuestionsContentProbs) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  return (
    <div className={` ${currentLanguage === "ar" ? "rtl" : "ltr"} flex justify-center items-center w-[16.8rem] custom-height-mq:w-[57.875rem] h-[9rem] bg-[#F0F0F0] p-5 overflow-auto`}>
      <div className="font-roboto text-[0.6rem] custom-height-mq:text-base font-normal custom-height-mq:leading-6 tracking-normal text-[#5A5A5A]">
        {answer}
      </div>
    </div>
  );
}

export default QuestionsContent;
