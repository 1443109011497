import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import EngIcon from "./assets/EngIcon.svg";

interface HeaderIconProps {
  icon: string;
  width: string;
  height: string;
  margin?: string;
  alt: string;
  onClick? : () => void;
  Language?: boolean;
}

function HeaderIcon({ icon, width, height, margin, alt , onClick ,Language }: HeaderIconProps) {
  const iconClass = `${margin ? margin : ""} ${width ? width : ""} ${height ? height : ""} ${alt === "Search" ? "hidden custom-height-mq:block":""} hover:headerIconsHover hover:cursor-pointer hover:scale-[1.2]`;
  const currentLanguage = useSelector((state: RootState) => state.language.language);

 

  return (
        <img
          src={Language ?  (currentLanguage === "en" ? icon : EngIcon) : icon}
          alt={alt}
          className={iconClass}
          onClick={onClick}
        />
  );
}

export default HeaderIcon;
