interface WhoWeAreHeaderProps{
    title: string;
}

function WhoWeAreHeader ({title} : WhoWeAreHeaderProps){
    return (
        <div className="flex justify-center text-justify custom-height-mq:text-center text-[0.75rem] leading-[1.1rem] custom-height-mq:text-2xl font-[500] custom-height-mq:leading-9 tracking-normal text-[#1C4585] w-[18.5625rem] custom-height-mq:w-[65.8125rem]">
            {title}
        </div>
    )
}


export default WhoWeAreHeader;