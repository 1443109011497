import AppPlacesCard from './AppPlacesCard';
import { useTranslation } from 'react-i18next';

interface ApplicationPlacesContentProbs {
  places: Array<any>;
}

function ApplicationPlacesContent({ places }: ApplicationPlacesContentProbs) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 custom-height-mq:gap-0 custom-height-mq:flex-row w-[18.4375rem] custom-height-mq:w-[50rem] justify-between items-center">
      {places[0] && (
        <AppPlacesCard
          icon={places[0]['icon']}
          title={places[0]['title']}
          image={places[0]['image']}
        />
      )}
      {places[1] && (
        <AppPlacesCard
          icon={places[1]['icon']}
          title={places[1]['title']}
          image={places[1]['image']}
        />
      )}
      {places[2] && (
        <AppPlacesCard
          icon={places[2]['icon']}
          title={places[2]['title']}
          image={places[2]['image']}
        />
      )}
    </div>
  );
}

export default ApplicationPlacesContent;
