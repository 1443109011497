import React from 'react';
import OutputIcon from "../../assets/Output.svg";
import AddIcon from "../../assets/Add.svg";
import MixingCard from "./MixingCard";

interface MixingContentProps {
  mixing: Array<{
    id: number;
    title: string;
    required_quantity: string;
    image: string;
  }>;
}

function MixingContent({ mixing }: MixingContentProps) {

  return (
    <div className="flex flex-col custom-height-mq:flex-row gap-2 justify-start items-center">
      {mixing.map((item, index) => (
        <React.Fragment key={item.id}>
          {(index > 0 && index !== mixing.length - 1) && <img src={AddIcon} alt="Add" className="w-[1.5rem] h-[1.5rem]" />}
          {index === mixing.length - 1 && <img src={OutputIcon} alt="Output" className="w-[1.5rem] h-[1.5rem]" />}
          <MixingCard
            icon={item.image}
            title={(item.title)}
            weight={(item.required_quantity)}
          />
        </React.Fragment>
      ))}
    </div>
  );
}

export default MixingContent;
