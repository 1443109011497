import { useState } from 'react';
import TopArrow from '../Home/assets/TopArrow.svg';
import TopIcon from '../Home/assets/Top.svg';
import { ReactComponent as BlueTopArrow } from '../Home/assets/BlueArrowTop.svg';
import { ReactComponent as BlueTop } from '../Home/assets/BlueTop.svg';


interface NavigateTopButtonProps{
  Awards ?: boolean;
}

function NavigateTopButton({Awards} : NavigateTopButtonProps) {
  const [isHovered, setIsHovered] = useState(false);

  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button
      className={`fixed focus:outline-none bottom-[15%] ${Awards ? "left-5" : "right-5"} z-50 flex flex-col justify-center items-center ContextCardShadow hover:bg-[#FBAD4A] bg-white w-[1.75rem] h-[1.75rem] custom-height-mq:w-[2.9375rem] custom-height-mq:h-[2.75rem]`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleButtonClick}
    >
      {isHovered ? (
        <>
          <BlueTopArrow className="w-[0.5rem] h-[0.703rem] custom-height-mq:w-[1.2rem] custom-height-mq:h-[1.2rem]" />
          <BlueTop className="w-[0.8125rem] h-[0.5rem] custom-height-mq:w-[1.3rem] custom-height-mq:h-[1rem]" />
        </>
      ) : (
        <>
          <img src={TopArrow} alt="Icon" className="w-[0.5rem] h-[0.703rem] custom-height-mq:w-[1.2rem] custom-height-mq:h-[1.2rem]" />
          <img src={TopIcon} alt="Icon" className="w-[0.8125rem] h-[0.5rem] custom-height-mq:w-[1.3rem] custom-height-mq:h-[1rem]"  />
        </>
      )}
    </button>
  );
}

export default NavigateTopButton;
