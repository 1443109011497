import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface TechCharContentProbs {
  technicalCharacteristic: Array<any>;
  image: string;
}

function TechCharContent({technicalCharacteristic , image} : TechCharContentProbs) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);

  return (
    <div className="flex flex-col custom-height-mq:flex-row w-[18.4375rem] custom-height-mq:w-[50rem] gap-4 justify-between items-center">
      <ul className={`text-xs custom-height-mq:text-base text-justify capitalize font-normal list-disc ${currentLanguage === "ar" ? "mr-[2rem]":"ml-[2rem]"}  text-[#5A5A5A]`}>
      {technicalCharacteristic.map((item) => (
          <li key={item.id}>{item.content}</li>  
        ))}
      </ul>
      <img src={image} alt="Description" className="w-[23.4375rem] h-[13.375rem]" />
    </div>
  );
}

export default TechCharContent;
