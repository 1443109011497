import { useTranslation } from 'react-i18next';
import ProductsCard from '../../Home/components/ProductsCard/ProductsCard';

interface AccordionContentProbs {
  context: string;
  image: string;
  recommendedProducts?: Array<any>;
}

function AccordionContent({
  context,
  image,
  recommendedProducts,
}: AccordionContentProbs) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-5 custom-height-mq:gap-9 mt-0 custom-height-mq:mt-[-1rem] mb-[1.5rem]">
      <div className="flex flex-col custom-height-mq:flex-row justify-between items-center">
        <div className="text-[0.65rem] leading-[1rem] custom-height-mq:text-sm font-normal text-[#5A5A5A] text-justify capitalize w-full custom-height-mq:w-[50%]">
          {context}
        </div>
        <img
          src={image}
          alt="Context"
          className="w-full h-[11.25rem] custom-height-mq:w-[22.5625rem] custom-height-mq:h-[16.75rem]"
        />
      </div>
      {recommendedProducts && recommendedProducts.length > 0 && (
        <>
          <div className="font-normal text-lg self-center custom-height-mq:self-start custom-height-mq:text-3xl text-[#1EBAE5]">
            {t('Our recommended products')}
          </div>
          <div className="flex flex-row gap-5 self-center custom-height-mq:self-start">
            <ProductsCard
              key={recommendedProducts[0].id}
              id={recommendedProducts[0].id}
              title={recommendedProducts[0]['title']}
              subtitle={recommendedProducts[0]['subtitle']}
              description={recommendedProducts[0]['description']}
              weight={recommendedProducts[0]['weight']}
              unitOfMeasure={recommendedProducts[0]['unit_of_measure']}
              coverageRate={recommendedProducts[0]['coverage_rate']}
              coverageRateUnit={recommendedProducts[0]['coverage_rate_unite']}
              howToApply={recommendedProducts[0]['how_to_apply']}
              cardImage={recommendedProducts[0]['card_image']['image']}
              descriptionAdvantages={
                recommendedProducts[0]['description_advantages']
              }
              pdf={recommendedProducts[0]['pdf']}
              places={recommendedProducts[0]['places']}
              video={recommendedProducts[0]['videos']}
            />
            <div className="hidden custom-height-mq:block">
              <ProductsCard
                key={recommendedProducts[1].id}
                id={recommendedProducts[1].id}
                title={recommendedProducts[1]['title']}
                subtitle={recommendedProducts[1]['subtitle']}
                description={recommendedProducts[1]['description']}
                weight={recommendedProducts[1]['weight']}
                unitOfMeasure={recommendedProducts[1]['unit_of_measure']}
                coverageRate={recommendedProducts[1]['coverage_rate']}
                coverageRateUnit={recommendedProducts[1]['coverage_rate_unite']}
                howToApply={recommendedProducts[1]['how_to_apply']}
                cardImage={recommendedProducts[1]['card_image']['image']}
                descriptionAdvantages={
                  recommendedProducts[1]['description_advantages']
                }
                pdf={recommendedProducts[1]['pdf']}
                places={recommendedProducts[1]['places']}
                video={recommendedProducts[1]['videos']}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AccordionContent;
