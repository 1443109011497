import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

interface FeatureBenefitsProbs {
  header: string;
  feature: boolean;
  bgColor: string;
  image: string;
  items : Array<any>;
}

function FeaturesBenefits({ header, feature, bgColor , image , items }: FeatureBenefitsProbs) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  return (
    <div className={`w-screen custom-height-mq:w-full min-h-[16.625rem] flex flex-col items-center justify-center gap-5 px-[2rem] custom-height-mq:px-[5.125rem] py-[1.75rem] ${bgColor ? bgColor : ""}`}>
      <div className="text-xs custom-height-mq:text-2xl font-bold text-[#1C4585] text-center">
        {header}
      </div>

      <div className="flex flex-col gap-5 custom-height-mq-landscape:flex-row custom-height-mq:flex-row justify-between w-full">
        <div className="w-[18.4375rem] custom-height-mq:w-[45%]">
          <ul className={`list-disc  ${currentLanguage === "ar" ? "mr-[1.5rem] custom-height-mq:mr-0":"ml-[1.5rem] custom-height-mq:ml-0"} text-[0.65rem] leading-[1rem] custom-height-mq:text-sm font-normal text-justify text-[#5A5A5A]`}>
          {items.map((item: any) => (
                        <li key={item.id}>{(item.content)}</li>
                    ))}
          </ul>
        </div>
        <div className="w-full custom-height-mq-landscape:w-[50%] custom-height-mq:w-[50%] flex flex-row gap-[1rem] justify-between">
          <img src={image} alt="Benefits" className="w-full h-full" />
        </div>
      </div>
    </div>
  );
}

export default FeaturesBenefits;
