interface CementAcrylicProbs {
    image: string;
    header: string;
    content: string;
  }
  
  function CementAcrylic({ image, header, content }: CementAcrylicProbs) {
    return (
      <div className="bg-[#1EBAE5] w-full custom-height-mq:w-[26.8025rem] h-full custom-height-mq:h-[29.3125rem] bg-opacity-10 flex flex-col justify-start gap-3 custom-height-mq:gap-5 items-center">
        <img src={image} alt={header} className="w-full h-fit" />
        <div className="text-xs custom-height-mq:text-xl text-[#1C4585] font-bold text-center">
          {header}
        </div>
        <div className="text-xs custom-height-mq:text-sm text-[#5A5A5A] line-clamp-none px-[1rem] pb-[1.5rem] custom-height-mq:px-0 custom-height-mq:pb-0 custom-height-mq:line-clamp-[7] custom-height-mq-landscape:line-clamp-[7] font-normal w-[20.5rem] text-justify capitalize">
          {content}
        </div>
      </div>
    );
  }
  
  export default CementAcrylic;
  