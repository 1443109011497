interface SystemsCardProps {
    title: string;
  }
  
  function SystemsCardHeader({ title }: SystemsCardProps) {
    return (
      <div className="text-sm custom-height-mq:text-2xl font-bold leading-[1.5rem] custom-height-mq:leading-9 text-center custom-height-mq:text-justify text-[#FBAD4A]">
        {title}
      </div>
    );
  }
  
  export default SystemsCardHeader;
  