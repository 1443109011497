interface CardDataContentProps {
  title: string;
}

function CardDataContent({ title }: CardDataContentProps) {
  return (
    <div className="text-white w-[90%] text-center font-normal text-xs overflow-hidden line-clamp-[9]">
      {title}
    </div>
  );
}

export default CardDataContent;
