import React from "react";
import ExpandingButton from "../ExpandingButton";
import SystemsCardContent from "./SystemsCardContent";
import SystemsCardHeader from "./SystemsCardHeader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface SystemsCardProbs {
  image: string;
  title : string;
  description : string;
  id: number;
}

function SystemsCard({image , title, description , id} : SystemsCardProbs) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  const { t } = useTranslation();
  return (
    <div className={` ${currentLanguage === "ar" ? "rtl" : "ltr"} flex flex-col w-min custom-height-mq:flex-row items-center custom-height-mq:w-[75rem] h-[40rem] custom-height-mq:h-[20rem]`}>
      <div className="min-w-[40%] h-full">
        <img
          src={image}
          alt="Chema Protection"
          className="h-full w-full"
          loading="lazy"
        />
      </div>
      <div className="min-w-[60%] gap-9 h-full p-[2rem] custom-height-mq:p-[3.5rem] flex flex-col justify-center items-start bg-[#1C4585]" >
        <div className="flex flex-col gap-3">
        <SystemsCardHeader title={title} />
        <SystemsCardContent title={description} />
        </div>
        <ExpandingButton
          title={t("Who We Are")}
          bgColor="bg-[#D7D7D7]"
          hoverBgColor="bg-[#FBAD4A]"
          textColor="text-[#1C4585]"
          hoverTextColor="text-[#1C4585]"
          IconType="Normal"
          System = {true}
          id={id}
        />
      </div>
    </div>
  );
}

export default React.memo(SystemsCard);
