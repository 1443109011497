import AOS from 'aos';
import { useEffect } from 'react';

interface ContentTypographyProps {
  text: string;
  activeDotSection1: number;
}

function ContentTypography({ text , activeDotSection1 }: ContentTypographyProps) {

  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div key={activeDotSection1} data-aos="fade-up-right" data-aos-duration="500" className="text-justify text-[#D7D7D7] text-base w-[17.6875rem] custom-height-mq:w-1/2 whitespace-normal break-words capitalize">
      {text}
    </div>
  );
}

export default ContentTypography;
