import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { usePostAwardsDataMutation } from '../../../store/apiService';
import ContactUsButton from "../../Main components/ContactUsButton";
import CalcModalButton from "../../Main components/Modals/CalcModalButton";
import NavigateTopButton from "../../Main components/NavigateTopButton";
import LoginImage from "../assets/LoginImage.svg";
import ChemaGroupBlueIcon from "../assets/ChemaGroupBlueIcon.svg";
import UnderlineHeader from "../../Main components/UnderlineHeader";
import AwardsHeader from "../AwardsHeader";
import SideBar from "../SideBar";
import AwardsChemaImage from "../assets/AwardsChemaImage.svg";
import InputLabelAwards from "./InputLabelAwards";
import PagesTitle from "../../Main components/pagesTitle";
import TermsAndConditionsIcon from "../assets/TermsAndConditionsIcon.svg";
import PrizesIcon from "../assets/Prizes.svg";
import RegisterIcon from "../assets/RegisterIcon.svg";
import LoginIcon from "../assets/LoginIcon.svg";
import Header from "../../Main components/Header/Header";
import MobileBottomNavBar from "../../Main components/MobileBottomNavBar/MobileBottomNavBar";
import { setLoggedin } from "../../../store/authSlice";
import { setUser } from '../../../store/userSlice';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store/store';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [nationalId, setNationalId] = useState('');
  const [postAwardsData] = usePostAwardsDataMutation();
  const loggedin = useSelector((state: RootState) => state.auth.loggedin);

  const handleLogin = async () => {
    try {
      const response = await postAwardsData({ formType: 'login', national_id: nationalId }).unwrap();
      const { token, data: { user } } = response;

      // Save token to local storage
      localStorage.setItem('token', token);

      // Save user data to Redux store
      dispatch(setUser({ user, token }));
      dispatch(setLoggedin(true));
      navigate("/Awards");

      message.success('Login successful');
    } catch (error) {
      message.error('Login failed: ' + (error instanceof Error ? error.message : 'Please enter a valid national id'));
    }
  };

  const BottomNavBarIcons = [
    TermsAndConditionsIcon,
    PrizesIcon,
    RegisterIcon,
    LoginIcon,
  ];
  const BottomNavBarTitles = [
    "الشروط والأحكام",
    "جوائز المسابقة",
    "التسجيل في البرنامج",
    "تسجيل الدخول",
  ];

  return (
    <div className="arabicLanguage relative flex flex-col min-h-screen">
      <Header Active="" awards={true} />
      <MobileBottomNavBar
        icons={BottomNavBarIcons}
        titles={BottomNavBarTitles}
        active={"تسجيل الدخول"}
      />
      <ContactUsButton Awards={true} />
      <NavigateTopButton Awards={true} />
      <AwardsHeader />
      <div className="flex flex-row h-full overflow-hidden justify-center pt-0 custom-height-mq:pt-[6.25rem] items-start right-0 relative rtl">
        <SideBar />
        <div className="flex flex-col gap-6 custom-height-mq:gap-9 w-screen custom-height-mq:w-[62.125rem] py-0 custom-height-mq:py-[5rem] mr-[14rem]">
          <img
            src={AwardsChemaImage}
            alt="Awards"
            className="w-full object-cover h-[14rem] block custom-height-mq:hidden"
          />
          <div className="relative self-center top-[-2.5rem] custom-height-mq:top-[-3.5rem] block custom-height-mq:hidden h-full overflow-auto">
            <PagesTitle title="تسجيل الدخول" Awards={true} />
          </div>
          <div className="w-fit self-center">
            <UnderlineHeader title="تسجيل الدخول" Awards={true} />
          </div>
          <div className="flex flex-col self-center custom-height-mq:self-auto gap-3">
            <InputLabelAwards text="رقم البطاقة الشخصية للمشترك *" />
            <input
              className="w-[18.375rem] h-[1.75rem] custom-height-mq:w-full custom-height-mq:h-[3rem] bg-[#5A5A5A] bg-opacity-10 pr-[1rem]"
              value={nationalId}
              onChange={(e) => setNationalId(e.target.value)}
            />
          </div>
          <div className="font-bold text-xs self-center custom-height-mq:self-auto custom-height-mq:text-base text-[#5A5A5A] arabicLanguage">
            الحقول التي بها علامة النجمة (*) هي حقول مطلوبة يجب ملؤها.
          </div>
          <div className="flex flex-row justify-center items-center gap-2 custom-height-mq:gap-8 mt-[1rem]">
            <CalcModalButton
              title="تسجيل الدخول"
              bgColor="bg-[#FBAD4A]"
              TextColor="text-[#1C4585]"
              hoverBgColor="bg-[#1C4585]"
              hoverTextColor="text-white"
              Awards={true}
              Login={true}
              isLogin={loggedin}
              onClick={handleLogin}
            />
            <CalcModalButton
              title="التسجيل في البرنامج"
              bgColor="bg-[#D7D7D7]"
              TextColor="text-[#868686]"
              hoverBgColor="bg-[#1C4585]"
              hoverTextColor="text-white"
              Awards={true}
              Login={true}
              isLogin={true}
            />
          </div>
          <img
            src={LoginImage}
            alt="Login"
            className="h-[7.5rem] w-[19.9375rem] object-cover self-center mt-0 custom-height-mq:w-full custom-height-mq:h-[13.5rem] custom-height-mq:mt-[3rem]"
          />
          <img
            src={ChemaGroupBlueIcon}
            alt="Chema Group"
            className="w-[9.98625rem] h-[3.25rem] self-center my-[2rem] mb-[6rem] custom-height-mq:mb-0 custom-height-mq:mt-[7rem]"
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
