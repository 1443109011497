import GoalsImage from '../../assets/Message&GoalsImage.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

interface GoalsContentProbs {
    items : Array <any>;
}

function GoalsContent({items} : GoalsContentProbs) {
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    return(
        <div className="flex flex-col custom-height-mq:flex-row gap-4 custom-height-mq:gap-8 custom-height-mq:mt-[1rem] mb-[1.75rem]">
            <ul className={`text-[0.65rem] leading-[1rem] custom-height-mq:text-base font-normal custom-height-mq:leading-[1.625rem] tracking-normal text-justify ${currentLanguage === "ar" ? "rtl mr-4 custom-height-mq:mr-8" : "ltr ml-4 custom-height-mq:ml-8"} text-[#5A5A5A] capitalize list-disc`}>
            {items[0].list.map((item: any) => (
                        <li key={item.id}>{(item.content)}</li>
                    ))}
            </ul>
            <img src={items[0]['images'][0].image} alt="Goals" className='object-cover w-[18.4375rem] h-[15.75rem] custom-height-mq:w-[33.0625rem] custom-height-mq:h-[20rem]' />
        </div>
    )
}

export default GoalsContent;