interface InputLabelAwardsProps{
    text : string;
}


function InputLabelAwards ({text} : InputLabelAwardsProps) {
    return(
        <div className="font-normal text-sm custom-height-mq:text-2xl arabicLanguage text-[#1C4585]">{text}</div>
    )
}

export default InputLabelAwards;