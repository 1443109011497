import { Modal } from "antd";
import ModalIcon from "./ModalIcon";
import ExpandingButton from "../../Home/components/ExpandingButton";
import WeightCard from "./WeightCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useTranslation } from "react-i18next";

interface InformationModalProps {
  visible: boolean;
  onCancel: () => void;
  cardImage ?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  weight?: number;
  unitOfMeasure?: string;
  descriptionAdvantages ?: Array<any>;
  places?: Array<any>;
  howToApply ?: string;
  productID: number;
}


function InformationModal({ visible, onCancel , cardImage , title , subtitle , description , weight , unitOfMeasure , descriptionAdvantages , places , howToApply , productID }: InformationModalProps) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  const { t } = useTranslation();

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      footer={false}
      centered={true}
      className="!w-[unset]"
      styles={{
        mask: { backgroundColor: "rgba(28, 69, 133, 0.8)" },
        content: {
          borderRadius: "0px",
          maxWidth: "52.75rem",
          maxHeight: "90vh",
          overflow: "auto",
        },
      }}
    >
      <div className={`relative flex flex-col justify-center items-center gap-6 p-0 custom-height-mq:p-[2rem] pb-[0.5rem] ${currentLanguage === "ar" ? "rtl" : "ltr"}`}>
        <img
          src={cardImage}
          alt={title}
          className="w-[9.5625rem] h-[8.78125rem] custom-height-mq:w-[26rem] custom-height-mq:h-auto"
        />
        <div className="text-center text-[#1C4585] text-sm custom-height-mq:text-4xl font-bold">
          {title}
        </div>
        <div className="text-center text-xs custom-height-mq:text-base font-normal leading-26 text-[#5A5A5A]">
          {subtitle}
        </div>
        <div className="text-xs custom-height-mq:text-base font-normal leading-26 text-[#5A5A5A]">
          {description}
        </div>
        <ul className="self-start text-xs custom-height-mq:text-base p-[revert] list-disc text-[#1C4585]">
        {descriptionAdvantages && descriptionAdvantages.map(advantage => (
    <li key={advantage.id}>{advantage.content}</li>  
  ))}
        </ul>
        <div className="self-start text-xs custom-height-mq:text-base font-normal leading-26 text-[#5A5A5A]">
          {howToApply}
        </div>
        <div className="flex flex-row justify-center items-center gap-1 custom-height-mq:gap-5 self-baseline">
        {places && places.map(place => (
             <ModalIcon key={place.id} icon={place.image} title={place.title} />
          ))}
        </div>
        <div className="self-start text-lg pt-[1rem]">
          <ExpandingButton
            title={t("View Product")}
            bgColor="bg-[#1C4585]"
            hoverBgColor="bg-[#FBAD4A]"
            textColor="text-[#FFFFFF]"
            hoverTextColor="text-[#1C4585]"
            IconType="White"
            id={productID}
          />
        </div>
        <WeightCard weight={weight} unitOfMeasure={unitOfMeasure} />
      </div>
    </Modal>
  );
}

export default InformationModal;
