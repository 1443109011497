import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

interface InstallationMethodCardProps{
    number: string;
    title: string;
    paragraph: string;
    image : string;
}

function InstallationMethodCard ({number,title,paragraph, image} : InstallationMethodCardProps) {
    const currentLanguage = useSelector((state: RootState) => state.language.language);

    
    return(
        <div className="flex flex-col gap-1 w-[11.5625rem] h-[12.0625rem] justify-start">
            <div className="flex flex-row gap-2 justify-start items-center">
                <div className="font-bold text-base custom-height-mq:text-2xl text-[#1C4585]">{number}</div>
                <div className="font-bold text-xs custom-height-mq:text-sm text-[#5A5A5A]">{title}</div>
            </div>
            <img src={image} alt={title} className="w-[11.5625rem] h-[7.4375rem]" />
            <ul className={`list-disc ${currentLanguage === "ar" ? "mr-[1rem]" : "ml-[1rem]"} text-[#5A5A5A] font-normal text-[0.6rem]`}>
                <li>{paragraph}</li>
            </ul>
        </div>
    )
}

export default InstallationMethodCard;