interface WeightCardProps{
    weight ?: number;
    unitOfMeasure ?: string;
}

function WeightCard ({weight , unitOfMeasure} : WeightCardProps){
    return(
        <div className="absolute top-[2.5rem] left-[1rem] custom-height-mq:top-[9rem] custom-height-mq:left-[5rem] w-[3.21875rem] h-[1.84375rem] custom-height-mq:w-[6.4375rem] custom-height-mq:h-[3.6875rem] text-xs custom-height-mq:text-xl font-bold text-[#1C4585] border-[#1C4585] border-solid border-2 flex items-center justify-center">
            {weight}{' '}{unitOfMeasure}
        </div>
    )
}

export default WeightCard;