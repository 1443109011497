import ProductCardPoint from "./ProductCardPoints";

interface AwardsProductCardProbs {
    title : string;
    points : number;
    image : string;
}

function AwardsProductCard({title, points , image} : AwardsProductCardProbs) {
    return(
        <div className="w-[10rem] h-[14.025rem] custom-height-mq:w-[17.0625rem] custom-height-mq:h-[23.375rem] flex flex-col justify-start gap-3 items-center custom-height-mq:justify-evenly border-2 border-[#FBAD4A]">
            <img src={image} alt="Chema Proof 105" className="w-full h-fit" />
            <div className="font-bold text-sm custom-height-mq:text-xl text-[#1C4585] arabicLanguage">{title}</div>
            <ProductCardPoint Points={`${points} نقطة`} />
        </div>
    )
}

export default AwardsProductCard;