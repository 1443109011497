import { useSelector } from "react-redux";
import SearchBar from "./SearchBar";
import { RootState } from "../../../../store/store";
import { useTranslation } from "react-i18next";

function HelpSearchCard() {
    const currentLanguage = useSelector((state: RootState) => state.language.language);
    const { t } = useTranslation();
    return (
        <div className={`flex flex-col ${currentLanguage === "ar" ? "rtl" : "ltr"} gap-5 bg-[#1F3566] items-center justify-center max-w-[20.9375rem] w-[20.9375rem] custom-height-mq:max-w-[50vw] custom-height-mq:w-[50vw] h-[8.5625rem] custom-height-mq:h-[16.75rem] relative top-[-2rem] custom-height-mq:top-[-4rem] bg-opacity-80 self-center`}>
            <div className="font-bold text-base custom-height-mq:text-3xl mt-[-2rem] custom-height-mq:mt-[-5rem] leading-13 text-center text-white">{t("How can we help you?")}</div>
            <SearchBar />
        </div>
    )
}

export default HelpSearchCard;