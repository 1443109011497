interface CardContextProps {
    title: string;
  }
  
  function CardContext({ title }: CardContextProps) {
    return (
      <div className="font-bold duration-300 translate-y-[-1rem] group-hover:opacity-80 transform pl-[1rem] pr-[1rem] text-base text-center text-[#1C4585] overflow-hidden">
        <div className="line-clamp-2">
          {title}
        </div>
      </div>
    );
  }
  
  export default CardContext;
  