import React from 'react';
import { useState } from 'react';

interface PaginationDotsProps {
  totalDots: number;
  activeDot: number;
  onChange: (index: number) => void;
}

function PaginationDots({ totalDots, activeDot, onChange }: PaginationDotsProps) {
  const [hoveredDot, setHoveredDot] = useState<number | null>(null);

  return (
    <div className="flex gap-2">
      {Array.from({ length: totalDots }, (_, index) => (
        <div
          key={index}
          className={`w-2 h-2 bg-gray-300 rounded-full transition-width duration-300 transform cursor-pointer ${
            activeDot === index ? 'w-8 !bg-[#FBAD4A] transition-width duration-300 transform' : ''
          } ${hoveredDot === index ? '' : ''}`}
          onMouseEnter={() => setHoveredDot(index)}
          onMouseLeave={() => setHoveredDot(null)}
          onClick={() => onChange(index)}
        />
      ))}
    </div>
  );
}

export default React.memo(PaginationDots);
