import React from 'react';
import ArrowDownloadIcon from "../../assets/ArrowDownloadInformation.svg";
import EyeIcon from "../../assets/EyeIcon.svg";
import CalcIcon from "../../assets/CalcIcon.svg";
import CamIcon from "../../assets/CamIcon.svg";
import InformationCard from "./InformationCard";
import WeightInformationCard from "./WeightInformationCard";
import ProductCardDataContent from "./ProductCardDataContent";
import NonExpandingButton from "../ApplicationsCard/NonExpandingButton";
import { useTranslation } from 'react-i18next';

interface ProductsCardProbs {
  title : string;
  subtitle: string;
  description : string;
  weight : number;
  unitOfMeasure : string;
  coverageRate : number;
  coverageRateUnit : string;
  howToApply : string;
  cardImage : string;
  descriptionAdvantages : Array<any>;
  pdf: Array<any>;
  places: Array<any>;
  video : string;
  id: number;
}

function ProductsCard({title, id , subtitle , description , weight , unitOfMeasure , coverageRate , coverageRateUnit , video , howToApply , cardImage , descriptionAdvantages , pdf , places} : ProductsCardProbs) {
  const { t } = useTranslation();
  return (
    <div className="group relative gap-[0.5rem] custom-height-mq:gap-2 items-center flex flex-col w-[14.625rem] custom-height-mq:w-[17.0625rem] h-[21.5rem] custom-height-mq:h-[23.25rem] hover:bg-[#1C4585] hover:ContextCardShadow hover:border-[#1C4585] border-2 duration-500 transform border-solid border-[#FBAD4A]">
      <img
        src={cardImage}
        alt="Chema Product"
        className="mix-blend-overlay w-fit h-fit group-hover:hidden"
      />
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col gap-4 absolute top-[1.5rem] left-[1.5rem]">
          <InformationCard icon={EyeIcon} ModalType="Information" productID={id} cardImage={cardImage} title={title} subtitle={subtitle} description={description} descriptionAdvantages={descriptionAdvantages} places={places} howToApply={howToApply} weight={weight} unitOfMeasure={unitOfMeasure} />
          <InformationCard icon={CamIcon} ModalType="Video" video={video} />
          <InformationCard icon={CalcIcon} ModalType="Calc" title={title} subtitle={subtitle} cardImage={cardImage} coverageRate={coverageRate} coverageRateUnit={coverageRateUnit} unitOfMeasure={unitOfMeasure} weight={weight} />
          <InformationCard icon={ArrowDownloadIcon} ModalType="Download" title={title} subtitle={subtitle} cardImage={cardImage} unitOfMeasure={unitOfMeasure} weight={weight} pdf={pdf} />
        </div>
        <div className="flex flex-col w-[60%] gap-2 justify-center items-center absolute right-[1rem] top-[1.4rem]">
          <div className="font-bold leading-5 text-base text-[#FFFFFF] hidden group-hover:block">
            {title}
          </div>
          <div className="font-bold leading-5 text-xs text-center w-[10.4375rem] text-[#FFFFFF] hidden group-hover:block group-hover:!line-clamp-[3]">
          {subtitle}
          </div>
          <div className="hidden group-hover:block">
          <ProductCardDataContent title={description} />
          </div>
        </div>
        <div className="hidden group-hover:block bottom-5 absolute">
        <NonExpandingButton title={t("View Product")} bgColor='bg-[#D7D7D7]' hoverBgColor='bg-[#FBAD4A]' textColor='text-[#1C4585]' hoverTextColor='text-[#1C4585]' IconType='None' id={id} />
        </div>
      </div>
      <div className="flex flex-col gap-2 justify-center items-center">
        <div className="font-bold leading-7 text-lg text-[#1C4585] group-hover:hidden">
          {title}
        </div>
        <div className="line-clamp-2 font-bold text-center w-[10.4375rem] text-xs text-[#1C4585] group-hover:hidden">
        {subtitle}
        </div>
      </div>
      <WeightInformationCard weight={weight} unitOfMeasure={unitOfMeasure} />
    </div>
  );
}

export default React.memo(ProductsCard);
