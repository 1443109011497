import { useState } from "react";
import QuestionMarkIcon from "../../assets/QuestionMark.svg";
import ArrowDownGrey from "../../assets/ArrowDownGrey.svg";
import QuestionsContent from "./QuestionsContent";
import { ReactComponent as BlueQuestionMark } from "../../assets/BlueQuestionMark.svg";
import { ReactComponent as BlueTopArrow } from "../../assets/BlueTopArrow.svg";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";

interface DropDownContentProbs {
  question : string;
  answer : string;
}

function DropDownContent({question , answer} :DropDownContentProbs ) {
  const [isContentVisible, setIsContentVisible] = useState(false);
  const currentLanguage = useSelector((state: RootState) => state.language.language);

  const handleArrowClick = () => {
    setIsContentVisible(!isContentVisible);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <div className={`w-[19.375rem] custom-height-mq:w-[62.125rem] h-[3.5rem] custom-height-mq:h-[4.5rem] ${currentLanguage === "ar" ? "gap-[0.5rem]" : ""} flex flex-row justify-center items-center`}>
        {isContentVisible ? (
          <BlueQuestionMark className="w-[0.9rem] h-[0.9rem] custom-height-mq:w-[1.5rem] custom-height-mq:h-[1.5rem]" />
        ) : (
          <img
            src={QuestionMarkIcon}
            alt="Question Mark"
            className="w-[0.9rem] h-[0.9rem] custom-height-mq:w-[1.5rem] custom-height-mq:h-[1.5rem]"
          />
        )}
        <div
          className={`font-roboto  ${
            isContentVisible ? "text-[#1C4585]" : "text-[#5A5A5A]"
          }  text-left text-xs custom-height-mq:text-xl font-medium pl-[0.5rem] custom-height-mq:pl-[1rem] custom-height-mq:leading-8`}
        >
          {question}
        </div>
        <div className="flex-grow" />
        {isContentVisible ? (
          <BlueTopArrow
            onClick={handleArrowClick}
            className="w-[0.6rem] h-[0.6rem] custom-height-mq:w-[0.783125rem] custom-height-mq:h-[0.791875rem] hover:cursor-pointer"
          />
        ) : (
          <img
            src={ArrowDownGrey}
            alt="Arrow Down"
            className="w-[0.6rem] h-[0.6rem] custom-height-mq:w-[0.783125rem] custom-height-mq:h-[0.791875rem] hover:cursor-pointer"
            onClick={handleArrowClick}
          />
        )}
      </div>
      {isContentVisible && <QuestionsContent answer={answer} />}
    </div>
  );
}

export default DropDownContent;
