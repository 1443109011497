import FacebookPostIcon from "../../assets/FacebookPostIcon.png";

interface FacebookPostCardProb {
  uploadDate: string;
  context: string;
  postHeader: string;
  Active : boolean;
}

function FacebookPostCard({
  uploadDate,
  context,
  postHeader,
  Active
}: FacebookPostCardProb) {
  return (
    <div className={`flex flex-col transition-all duration-300 ease-in-out ${Active ? 'm-2 custom-height-mq:m-7' : 'm-2'} `}>
      <div className="flex flex-row gap-2">
        <img
          src={FacebookPostIcon}
          alt="Chema Facebook Icon"
          className="self-center w-[1.22625rem] h-[1.22625rem] custom-height-mq:w-fit custom-height-mq:h-fit"
          loading="lazy"
        />
        <div className="flex flex-col self-center">
          <div className={`text-[#1C4585] font-semibold  ${Active ? 'text-xs custom-height-mq:text-base' : 'text-[0.4rem] leading-[0.4rem] custom-height-mq:text-xs'}`}>
            {postHeader}
          </div>
          <div className="font-normal text-xs custom-height-mq:text-sm text-[#868686]">{uploadDate}</div>
        </div>
      </div>
      <div className={`text-[#5A5A5A] font-semibold  ${Active ? 'text-xs custom-height-mq:text-base' : 'text-xs custom-height-mq:text-xs'} pt-6 !arabicLanguage text-right`}>
        {context}
      </div>
    </div>
  );
}

export default FacebookPostCard;
