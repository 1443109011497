import { useSelector } from "react-redux";
import FilterHeader from "./FilterHeader";
import RightBlueArrow from "./assets/RightBlueArrow.svg";
import { Checkbox, Radio, RadioChangeEvent, Drawer } from "antd";
import { RootState } from "../../../store/store";
import { useState, useEffect } from "react";
import { AlignCenterOutlined } from '@ant-design/icons';

interface FilterProps {
  checkbox?: boolean;
  title: string;
  items: {
    sections: {
      name: string;
      subItems: { id: string; title: string }[];
    }[];
  };
  selectedProductId?: string;
  selectedProductIds?: string[];
  onFilterChange?: (id: string) => void;
}

function Filter({ title, items, checkbox, selectedProductId, onFilterChange , selectedProductIds }: FilterProps) {
  const currentLanguage = useSelector((state: RootState) => state.language.language);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRadioValue, setSelectedRadioValue] = useState<string | undefined>(selectedProductId);
  const [selectedCheckboxValues, setSelectedCheckboxValues] = useState<string[]>(selectedProductIds || []);

  useEffect(() => {
    if (selectedProductId && !checkbox) {
      setSelectedRadioValue(selectedProductId);
    }
  }, [selectedProductId, checkbox]);

  useEffect(() => {
    if (selectedProductIds && checkbox) {
      setSelectedCheckboxValues(selectedProductIds);
    }
  }, [selectedProductIds, checkbox]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedRadioValue(e.target.value);
    if (onFilterChange) {
      onFilterChange(e.target.value); // Trigger filter change handler
    }
  };

  const handleCheckboxChange = (e: any) => {
    const value = e.target.value;
    setSelectedCheckboxValues(prevValues =>
      prevValues.includes(value)
        ? prevValues.filter(v => v !== value)
        : [...prevValues, value]
    );
    if (onFilterChange) {
      onFilterChange(value); // Trigger filter change handler
    }
  };

  return (
    <>
      <div className="flex-col hidden min-w-[17rem] custom-height-mq:flex gap-6 px-[1.625rem] py-[2.75rem] bg-[#FBAD4A] bg-opacity-10">
        <FilterHeader title={title} />
        <div className="text-[#1C4585] flex flex-col gap-5">
          {items.sections.map((section, index) => (
            <div key={index} className="flex flex-col gap-3">
              <div className="font-bold">
                <div className="flex flex-row justify-start items-center gap-2">
                  <img
                    src={RightBlueArrow}
                    alt="Section"
                    className={`w-[0.435625rem] h-[0.829375rem] ${currentLanguage === "ar" ? "rotate-180 mt-[0.3rem]" : ""}`}
                  />
                  <div className="pt-[1px]">{section.name}</div>
                </div>
              </div>
              {section.subItems.map((subItem, subIndex) => (
                <label
                  key={subIndex}
                  className="pl-4 font-[400] text-sm flex flex-row justify-center items-center w-full"
                >
                  {subItem.title} <div className="flex-grow" />
                  {checkbox ? (
                    <Checkbox
                      value={subItem.id}
                      checked={selectedCheckboxValues.includes(subItem.id)}
                      onChange={handleCheckboxChange}
                    />
                  ) : (
                    <Radio.Group onChange={handleRadioChange} value={selectedRadioValue}>
                      <Radio value={subItem.id} />
                    </Radio.Group>
                  )}
                </label>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="w-full h-[3.75rem] px-[1.5rem] flex custom-height-mq:hidden flex-row items-center justify-center bg-[#FBAD4A] bg-opacity-10">
        <FilterHeader title={title} />
        <div className="flex-grow" />
        <AlignCenterOutlined className="w-[1.5em] h-[1.5rem] flex justify-center" onClick={showDrawer} />
        <Drawer
          placement="bottom"
          closable={false}
          onClose={closeDrawer}
          open={drawerVisible}
          height={"70%"}
          styles={{
            mask: { backgroundColor: "rgba(28, 69, 133, 0.8)" },
            content: {
              backgroundColor: "#FFFFFF",
              direction: currentLanguage === "ar" ? "rtl" : "ltr",
            },
          }}
        >
          <div className="text-[#1C4585] flex flex-col gap-5">
            {items.sections.map((section, index) => (
              <div key={index} className="flex flex-col gap-3">
                <div className="font-bold">
                  <div className="flex flex-row justify-start items-center gap-2">
                    <img
                      src={RightBlueArrow}
                      alt="Section"
                      className={`w-[0.435625rem] h-[0.829375rem] ${currentLanguage === "ar" ? "rotate-180 mt-[0.3rem]" : ""}`}
                    />
                    <div className="pt-[1px]">{section.name}</div>
                  </div>
                </div>
                {section.subItems.map((subItem, subIndex) => (
                  <label
                    key={subIndex}
                    className="pl-4 font-[400] text-sm flex flex-row justify-center items-center w-full"
                  >
                    {subItem.title} <div className="flex-grow" />
                    {checkbox ? (
                      <Checkbox
                        value={subItem.id}
                        checked={selectedCheckboxValues.includes(subItem.id)}
                        onChange={handleCheckboxChange}
                      />
                    ) : (
                      <Radio.Group onChange={handleRadioChange} value={selectedRadioValue}>
                        <Radio value={subItem.id} />
                      </Radio.Group>
                    )}
                  </label>
                ))}
              </div>
            ))}
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default Filter;
