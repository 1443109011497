import { useState } from 'react';
import DropDownContent from './DropDownContent';
import ViewMoreButton from './ViewMoreButton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';

interface  FrequentlyAskedQuestionsCardProbs{
  Questions : Array<any>;
}

function FrequentlyAskedQuestionsCard({Questions} : FrequentlyAskedQuestionsCardProbs) {
  const [showAllQuestions, setShowAllQuestions] = useState(false);
  const currentLanguage = useSelector((state: RootState) => state.language.language);

  return (
    <div className={`${currentLanguage === "ar" ? "rtl" : "ltr"} w-[19.375rem] custom-height-mq:w-[62.125rem] min-h-[29.875rem] flex flex-col justify-start items-center`}>
      <div>
        {Questions.slice(0, showAllQuestions ? Questions.length : 4).map((question) => (
          <DropDownContent key={question.id} question={question.question} answer={question.awnser} />
        ))}
      </div>
      {Questions.length > 4 && (
        <div className='py-[5rem] self-center'>
          <ViewMoreButton showAllQuestions={showAllQuestions} onClick={() => setShowAllQuestions(!showAllQuestions)} />
        </div>
      )}
    </div>
  );
}

export default FrequentlyAskedQuestionsCard;
