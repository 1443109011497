import { useRef, useState, useEffect, MouseEvent, TouchEvent } from 'react';

interface ScrollableBoxProps {
  activeDot: number;
  clients: Array<{ id: number; client_logo: string }>;
}

function ScrollableBox({ activeDot, clients }: ScrollableBoxProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      const maxScrollLeft = containerRef.current.scrollWidth - containerRef.current.clientWidth;
      const targetScrollLeft = maxScrollLeft * (activeDot / clients.length); // Adjust the formula based on the number of clients
      smoothScroll(containerRef.current, targetScrollLeft, 300); // 300 ms animation
    }
  }, [activeDot, clients.length]);

  function smoothScroll(element: any, target: any, duration: any) {
    const start = element.scrollLeft,
          change = target - start,
          startTime = performance.now();

    function animateScroll(time: any) {
      const elapsedTime = time - startTime;
      const fraction = elapsedTime / duration;
      const ease = fraction < 0.5 ? 2 * fraction * fraction : -1 + (4 - 2 * fraction) * fraction;

      element.scrollLeft = start + change * ease;
      if (elapsedTime < duration) {
        window.requestAnimationFrame(animateScroll);
      }
    }

    window.requestAnimationFrame(animateScroll);
  }

  function handleStart(e: MouseEvent | TouchEvent): void {
    e.preventDefault();
    if (containerRef.current) {
      setIsMouseDown(true);
      setStartX(
        'touches' in e ? e.touches[0].pageX - containerRef.current.offsetLeft : e.pageX - containerRef.current.offsetLeft
      );
      setScrollLeft(containerRef.current.scrollLeft);
    }
  }

  function handleMove(e: MouseEvent | TouchEvent): void {
    if (!isMouseDown || !containerRef.current) return;
    const x = 'touches' in e ? e.touches[0].pageX - containerRef.current.offsetLeft : e.pageX - containerRef.current.offsetLeft;
    const walkX = (x - startX) * 1.5; // Adjust sensitivity as needed
    containerRef.current.scrollLeft = scrollLeft - walkX;
  }

  function handleEnd(): void {
    setIsMouseDown(false);
  }

  return (
    <div className='relative'>
      <div
        ref={containerRef}
        className={`bg-white flex justify-center items-center w-screen h-[6.125rem] custom-height-mq:h-[10.625rem] overflow-x-auto ContextCardShadow no-scrollbar ${
          isMouseDown ? 'cursor-grabbing' : 'cursor-pointer'
        }`}
        onMouseDown={handleStart}
        onMouseMove={handleMove}
        onMouseUp={handleEnd}
        onTouchStart={handleStart}
        onTouchMove={handleMove}
        onTouchEnd={handleEnd}
      >
        <div className="flex flex-row justify-center items-center gap-8">
          {clients.map(client => (
            <img
              key={client.id}
              draggable="false"
              src={client.client_logo}
              alt={`Client ${client.id}`}
              className="pointer-events-none select-none w-[5.201875rem] h-[4.07rem] custom-height-mq:w-auto custom-height-mq:h-[8rem]"
              loading="lazy"
            />
          ))}
        </div>
        <div className="absolute inset-0 blending-shadow opacity-90 z-20" />
      </div>
    </div>
  );
}

export default ScrollableBox;
