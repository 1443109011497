interface FooterHeaderProps {
    title: string;
  }
  
  function FooterHeader({ title }: FooterHeaderProps) {
    return (
      <div className="font-bold text-left text-xl leading-[1.875rem] text-[#FBAD4A]">
        {title}
      </div>
    );
  }
  
  export default FooterHeader;
  