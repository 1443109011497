
interface AppPlacesCardProbs {
    icon:string;
    title: string;
    image: string;
}

function AppPlacesCard({icon,title , image} : AppPlacesCardProbs){
    return(
        <div className="flex flex-col justify-center items-center custom-height-mq:items-start w-[15.5625rem] h-[14rem] gap-[1rem]">
            <div className="flex flex-row justify-center items-center gap-3">
                <img src={icon} alt="Application Places" className="w-[3.125rem] h-[3.125rem]" />
                <div className="text-xs custom-height-mq:text-base font-normal text-[#5A5A5A]">{title}</div>
            </div>
            <img src={image} className="w-[16rem] h-[10rem]" alt="Product" />
        </div>
    )
}

export default AppPlacesCard;